import React, { useState } from "react";
import { Link } from "react-router-dom";
import Layout from "./../components/Layout/Layout";
import { Button, Card, CardContent, CardMedia, Grid, Typography, Box, Tabs, Tab } from "@mui/material";
import Banner from "../images/banner4.jpg";
import "../styles/HomeStyles.css";
import { products } from "../data/products"; // Import new products data

const Home = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // Extract unique categories from the products
  const categories = [...new Set(products.map((product) => product.category))];

  // Helper function to filter products by category
  const filterProductsByCategory = (category) => {
    return products.filter((product) => product.category === category);
  };

  return (
    <Layout>
      {/* Full-Screen Banner Section */}
      <Box
        sx={{
          backgroundImage: `url(${Banner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: "5rem 0", // Larger padding for more space
          textAlign: "center",
          position: "relative",
          minHeight: "70vh", // Make the banner more prominent
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.6)", // Dark overlay for contrast
            zIndex: 1,
          }}
        />
        <Typography
          variant="h2"
          color="white"
          sx={{
            position: "relative",
            zIndex: 2,
            fontWeight: "bold",
            fontSize: "3rem", // Larger font size for attention-grabbing text
            letterSpacing: "2px",
          }}
        >
          Best Electronics in Town
        </Typography>
        <Typography
          variant="h5"
          color="white"
          sx={{
            position: "relative",
            zIndex: 2,
            marginTop: "1rem",
            fontSize: "1.3rem", // Slightly larger subtitle
            letterSpacing: "1px",
          }}
        >
          Discover the latest Smart LED TVs, Coolers & Signage Solutions
        </Typography>
        <Link to="/products">
          <Button
            variant="contained"
            color="primary"
            sx={{
              marginTop: "2rem",
              fontSize: "1.5rem", // Larger text for CTA
              padding: "1rem 3rem", // More spacious button
              zIndex: 2,
              borderRadius: "50px",
              backgroundColor: "#FF6347", // Aggressive color
              "&:hover": { backgroundColor: "#FF4500" },
            }}
          >
            VIEW CATALOG
          </Button>
        </Link>
      </Box>

      {/* Product Catalog Section with Tabs */}
      <Box sx={{ padding: "3rem 1rem", backgroundColor: "#222", color: "white" }}>
        <Typography variant="h4" align="center" gutterBottom sx={{ fontWeight: "bold", fontSize: "2rem" }}>
          Product Catalog
        </Typography>

        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          centered
          sx={{
            "& .MuiTab-root": {
              fontSize: "1.3rem", // Default font size
              fontWeight: "600",
              textTransform: "none",
              color: "#fff", // White text on dark background
            },
            "& .MuiTab-root.Mui-selected": {
              color: "#FF6347", // Highlight active tab with an aggressive color
            },
            backgroundColor: "#111",
            borderRadius: "30px",
            overflowX: "auto",
            "& .MuiTabs-flexContainer": {
              flexWrap: "nowrap",
              padding: "0 1rem", // Optional: for some space between tabs
            },
            "@media (max-width: 600px)": {
              "& .MuiTab-root": {
                fontSize: "1rem", // Adjust font size for smaller screens
              },
              "& .MuiTabs-flexContainer": {
                display: "flex",
                justifyContent: "flex-start", // Align tabs to the left
                width: "100%",
                overflowX: "auto", // Ensure horizontal scrolling if needed
                WebkitOverflowScrolling: "touch", // Smooth scrolling on iOS
              },
            },
          }}
        >
          {categories.map((category, index) => (
            <Tab key={index} label={category} />
          ))}
        </Tabs>

        {/* Tab Content */}
        <Grid container spacing={4} justifyContent="center" sx={{ marginTop: "2rem" }}>
          {/* Dynamic Tab Content */}
          {categories.map((category, index) => (
            selectedTab === index && (
              <Grid container spacing={4} justifyContent="center" key={index}>
                {filterProductsByCategory(category).map((product, productIndex) => (
                  <Grid item xs={12} sm={6} md={4} key={productIndex}>
                    <Card
                      sx={{
                        maxWidth: 345,
                        boxShadow: 8,
                        borderRadius: "16px",
                        transition: "transform 0.3s",
                        "&:hover": { transform: "scale(1.05)" },
                      }}
                    >
                      <CardMedia
                        component="img"
                        alt={product.title}
                        height="250"
                        image={product.images[0]}
                      />
                      <CardContent sx={{ backgroundColor: "#333", padding: "2rem" }}>
                        <Typography variant="h6" component="div" sx={{ fontWeight: "bold", color: "#FF6347" }}>
                          {product.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ color: "#ddd" }}>
                          {product.description}
                        </Typography>
                        <Link to={`/product/${product.id}`}>
                          <Button
                            variant="contained"
                            color="primary"
                            sx={{
                              marginTop: "1rem",
                              padding: "0.8rem 2rem",
                              borderRadius: "30px",
                              backgroundColor: "#FF6347",
                              "&:hover": { backgroundColor: "#FF4500" },
                            }}
                          >
                            Learn More
                          </Button>
                        </Link>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            )
          ))}
        </Grid>
      </Box>
    </Layout>
  );
};

export default Home;
