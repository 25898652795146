export  const coordinates = [
    { name: "Janta Super Bazar", location: [22.728, 75.896], link: "https://maps.google.com/maps?q=22.728%2C75.896&z=17&hl=en" },
    { name: "Bhagirath Kirana Store", location: [22.7325, 75.8767], link: "https://maps.google.com/maps?q=22.7325%2C75.8767&z=17&hl=en" },
    { name: "Subhash Kirana", location: [22.7408, 75.8820], link: "https://maps.google.com/maps?q=22.7408%2C75.8820&z=17&hl=en" },
    { name: "D.K. Trader", location: [22.7403, 75.8818], link: "https://maps.google.com/maps?q=22.7403%2C75.8818&z=17&hl=en" },
    { name: "Bhagwan Bhai Ani Bhandhu", location: [22.7385, 75.8797], link: "https://maps.google.com/maps?q=22.7385%2C75.8797&z=17&hl=en" },
    { name: "Pawan G Traders", location: [22.7408, 75.8823], link: "https://maps.google.com/maps?q=22.7408%2C75.8823&z=17&hl=en" },
    { name: "Ipshita Super Market", location: [22.7586, 75.9216], link: "https://maps.google.com/maps?q=22.7586%2C75.9216&z=17&hl=en" },
    { name: "IpShita Super Store", location: [22.7586, 75.9216], link: "https://maps.google.com/maps?q=22.7586%2C75.9216&z=17&hl=en" },
    { name: "Satguru Super Bazzar", location: [22.7562, 75.8907], link: "https://maps.google.com/maps?q=22.7562%2C75.8907&z=17&hl=en" },
    { name: "Naina Provisions", location: [22.7563, 75.8934], link: "https://maps.google.com/maps?q=22.7563%2C75.8934&z=17&hl=en" },
    { name: "S.K.G Departmental Store", location: [22.7563, 75.8880], link: "https://maps.google.com/maps?q=22.7563%2C75.8880&z=17&hl=en" },
    { name: "P.T. Point", location: [22.7561, 75.8880], link: "https://maps.google.com/maps?q=22.7561%2C75.8880&z=17&hl=en" },
    { name: "Pankaj Store", location: [22.7540, 75.8878], link: "https://maps.google.com/maps?q=22.7540%2C75.8878&z=17&hl=en" },
    { name: "S.L.G. Trader", location: [22.7397, 75.8713], link: "https://maps.google.com/maps?q=22.7397%2C75.8713&z=17&hl=en" },
    { name: "Indira Gandhi", location: [22.7404, 75.8714], link: "https://maps.google.com/maps?q=22.7404%2C75.8714&z=17&hl=en" },
    { name: "Durga Shree Traders", location: [22.7463, 75.8676], link: "https://maps.google.com/maps?q=22.7463%2C75.8676&z=17&hl=en" },
    { name: "Ganesh Traders", location: [22.7460, 75.8631], link: "https://maps.google.com/maps?q=22.7460%2C75.8631&z=17&hl=en" },
    { name: "Muskan Kirana and General Store", location: [22.7477, 75.8729], link: "https://maps.google.com/maps?q=22.7477%2C75.8729&z=17&hl=en" },
    { name: "Agrawal Trading", location: [22.7474, 75.8719], link: "https://maps.google.com/maps?q=22.7474%2C75.8719&z=17&hl=en" },
    { name: "Jaiswala Trading Company", location: [22.7206, 75.8992], link: "https://maps.google.com/maps?q=22.7206%2C75.8992&z=17&hl=en" },
    { name: "Jain Brothers", location: [22.7206, 75.8984], link: "https://maps.google.com/maps?q=22.7206%2C75.8984&z=17&hl=en" },
    { name: "Jai Mahveer Kirana Stores", location: [22.7187, 75.8874], link: "https://maps.google.com/maps?q=22.7187%2C75.8874&z=17&hl=en" },
    { name: "Perfect Daily Needs", location: [22.6996, 75.8593], link: "https://maps.google.com/maps?q=22.6996%2C75.8593&z=17&hl=en" },
    { name: "Harikripa Traders", location: [22.7006, 75.8634], link: "https://maps.google.com/maps?q=22.7006%2C75.8634&z=17&hl=en" },
    { name: "Shubham Kirana Stores", location: [22.7020, 75.8751], link: "https://maps.google.com/maps?q=22.7020%2C75.8751&z=17&hl=en" },
    { name: "Mahadev Kirana General Stores", location: [22.6979, 75.8728], link: "https://maps.google.com/maps?q=22.6979%2C75.8728&z=17&hl=en" },
    { name: "Agrawal Kirana Stores", location: [22.6934, 75.8816], link: "https://maps.google.com/maps?q=22.6934%2C75.8816&z=17&hl=en" },
    { name: "Raj Kirana Stores", location: [22.6949, 75.8817], link: "https://maps.google.com/maps?q=22.6949%2C75.8817&z=17&hl=en" },
    { name: "Chhunnilal and Sons", location: [22.7079, 75.8770], link: "https://maps.google.com/maps?q=22.7079%2C75.8770&z=17&hl=en" },
    { name: "Jain Kirana Stores", location: [22.7097, 75.8754], link: "https://maps.google.com/maps?q=22.7097%2C75.8754&z=17&hl=en" },
    { name: "Kla Kirana and Everfresh", location: [22.6853, 75.8644], link: "https://maps.google.com/maps?q=22.6853%2C75.8644&z=17&hl=en" },
    { name: "Kesarimal Mangimal Khandelwal", location: [22.7077, 75.8753], link: "https://maps.google.com/maps?q=22.7077%2C75.8753&z=17&hl=en" },
    { name: "J M D Kirana", location: [22.6835, 75.8641], link: "https://maps.google.com/maps?q=22.6835%2C75.8641&z=17&hl=en" },
    { name: "Roopshi Kirana", location: [22.6836, 75.8641], link: "https://maps.google.com/maps?q=22.6836%2C75.8641&z=17&hl=en" },
    { name: "Sumran Kirana Stores", location: [22.6831, 75.8640], link: "https://maps.google.com/maps?q=22.6831%2C75.8640&z=17&hl=en" },
    { name: "Harsh Departmental Stores", location: [22.7077, 75.8753], link: "https://maps.google.com/maps?q=22.7077%2C75.8753&z=17&hl=en" },
    { name: "Naushad Pakiza Kirana", location: [22.7224, 75.8512], link: "https://maps.google.com/maps?q=22.7224%2C75.8512&z=17&hl=en" },
    { name: "Shree Janpriya Provision Stores", location: [22.7155, 75.8464], link: "https://maps.google.com/maps?q=22.7155%2C75.8464&z=17&hl=en" },
    { name: "Shivam Kirana Stores", location: [22.7134, 75.8461], link: "https://maps.google.com/maps?q=22.7134%2C75.8461&z=17&hl=en" },
    { name: "Shyam Agency", location: [22.7134, 75.8457], link: "https://maps.google.com/maps?q=22.7134%2C75.8457&z=17&hl=en" },
    { name: "Jay Maa Annapuran Traders", location: [22.7132, 75.8458], link: "https://maps.google.com/maps?q=22.7132%2C75.8458&z=17&hl=en" },
    { name: "Shree Sai Ram Traders", location: [22.7134, 75.8453], link: "https://maps.google.com/maps?q=22.7134%2C75.8453&z=17&hl=en" },
    { name: "Shree Bhagwati Kirana Stores", location: [22.7116, 75.8464], link: "https://maps.google.com/maps?q=22.7116%2C75.8464&z=17&hl=en" },
    { name: "Sitlani Provision Stores", location: [22.7104, 75.8464], link: "https://maps.google.com/maps?q=22.7104%2C75.8464&z=17&hl=en" },
    { name: "Shree Ji Provision and General Stores", location: [22.7025, 75.8403], link: "https://maps.google.com/maps?q=22.7025%2C75.8403&z=17&hl=en" },
    { name: "Patidar Kirana", location: [22.6864, 75.8245], link: "https://maps.google.com/maps?q=22.6864%2C75.8245&z=17&hl=en" },
    { name: "Piyush Kirana Stores", location: [22.6882, 75.8265], link: "https://maps.google.com/maps?q=22.6882%2C75.8265&z=17&hl=en" },
    { name: "Shree Ji Traders", location: [22.6844, 75.8307], link: "https://maps.google.com/maps?q=22.6844%2C75.8307&z=17&hl=en" },
    { name: "Lucky Kirana Store", location: [22.6813, 75.8640], link: "https://maps.google.com/maps?q=22.6813%2C75.8640&z=17&hl=en" },
    { name: "Hari Om Provision Stores", location: [22.6845, 75.8353], link: "https://maps.google.com/maps?q=22.6845%2C75.8353&z=17&hl=en" },
    { name: "Daddy Ki Dukan", location: [22.6932, 75.8818], link: "https://maps.google.com/maps?q=22.6932%2C75.8818&z=17&hl=en" },
    { name: "Shree Shyam Kirana", location: [22.6883, 75.8657], link: "https://maps.google.com/maps?q=22.6883%2C75.8657&z=17&hl=en" },
    { name: "Pappu Masala Bandhar", location: [22.7460, 75.8659], link: "https://maps.google.com/maps?q=22.7460%2C75.8659&z=17&hl=en" },
    { name: "Ratan Trader", location: [22.7198, 75.8456], link: "https://maps.google.com/maps?q=22.7198%2C75.8456&z=17&hl=en" },
    { name: "Maa Ambe Provision", location: [22.7283, 75.8306], link: "https://maps.google.com/maps?q=22.7283%2C75.8306&z=17&hl=en" },
    { name: "Sheetal Shree Provision", location: [22.7230, 75.8236], link: "https://maps.google.com/maps?q=22.7230%2C75.8236&z=17&hl=en" },
    { name: "Bhav Shankr Kirana", location: [22.7028, 75.8641], link: "https://maps.google.com/maps?q=22.7028%2C75.8641&z=17&hl=en" },
    { name: "Bade Balaji", location: [22.7025, 75.8641], link: "https://maps.google.com/maps?q=22.7025%2C75.8641&z=17&hl=en" },
    { name: "J.M.D. Trader", location: [22.7021, 75.8644], link: "https://maps.google.com/maps?q=22.7021%2C75.8644&z=17&hl=en" },
    { name: "Guru Kripa General Store", location: [22.7016, 75.8646], link: "https://maps.google.com/maps?q=22.7016%2C75.8646&z=17&hl=en" },
    { name: "Virat Kirana", location: [22.7456, 75.8146], link: "https://maps.google.com/maps?q=22.7456%2C75.8146&z=17&hl=en" },
    { name: "Agrawal Kirana Store", location: [22.7361, 75.8280], link: "https://maps.google.com/maps?q=22.7361%2C75.8280&z=17&hl=en" },
    { name: "Jaymala Store", location: [22.7361, 75.8284], link: "https://maps.google.com/maps?q=22.7361%2C75.8284&z=17&hl=en" },
    { name: "Gupta Masala Bandar", location: [22.7367, 75.8306], link: "https://maps.google.com/maps?q=22.7367%2C75.8306&z=17&hl=en" },
    { name: "Jyoti Kirana", location: [22.7333, 75.8327], link: "https://maps.google.com/maps?q=22.7333%2C75.8327&z=17&hl=en" },
    { name: "Sumit Kirana", location: [22.7337, 75.8824], link: "https://maps.google.com/maps?q=22.7337%2C75.8824&z=17&hl=en" },
    { name: "Darshan Lal Gopilal Agarwal", location: [22.7336, 75.8736], link: "https://maps.google.com/maps?q=22.7336%2C75.8736&z=17&hl=en" },
    { name: "Bharmal", location: [22.7396, 75.8814], link: "https://maps.google.com/maps?q=22.7396%2C75.8814&z=17&hl=en" },
    { name: "Hatti Supar Bazar", location: [22.7606, 75.8909], link: "https://maps.google.com/maps?q=22.7606%2C75.8909&z=17&hl=en" },
    { name: "Vijayvargi Trading", location: [22.7385, 75.8676], link: "https://maps.google.com/maps?q=22.7385%2C75.8676&z=17&hl=en" }
  ];
