import React, { useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import { Box, Card, CardActionArea, CardContent, CardMedia, Typography, Grid, Button, Tabs, Tab, TextField, InputAdornment, IconButton } from "@mui/material";
import { products } from "../data/products"; // Assuming products are in this file
import SearchIcon from "@mui/icons-material/Search";

const Products = () => {
  const [selectedCategory, setSelectedCategory] = useState("All"); // Default category is "All"
  const [tabIndex, setTabIndex] = useState(0); // Tab index for mobile
  const [searchQuery, setSearchQuery] = useState("");

  // Get all unique categories from the products array
  const categories = ["All", ...new Set(products.map((product) => product.category))];

  // Filter products based on search query and category
  const filteredProducts = products
    .filter((product) => {
      if (selectedCategory === "All") {
        // Show all products and filter by search query
        return product.title.toLowerCase().includes(searchQuery.toLowerCase());
      } else {
        // Filter by both category and search query
        return (
          product.category === selectedCategory &&
          product.title.toLowerCase().includes(searchQuery.toLowerCase())
        );
      }
    });

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    setSelectedCategory(categories[newValue]); // Update the selected category when the tab changes
  };

  return (
    <Layout>
      <Box sx={{ m: 3 }}>
        {/* Special Offers Section */}
        <Box sx={{ backgroundColor: "#ff9800", padding: "10px 0", marginBottom: "20px", textAlign: "center" }}>
          <Typography variant="h5" sx={{ color: "#fff" }}>
            🛍️ Special Offer: Get 20% off on selected items! Limited time only! 🛍️
          </Typography>
        </Box>

        {/* Search Bar */}
        <Box sx={{ display: "flex", justifyContent: "center", marginBottom: 3 }}>
          <TextField
            variant="outlined"
            placeholder="Search Products..."
            size="small"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ width: "50%" }}
          />
        </Box>

        {/* Horizontal Tabs for mobile and desktop */}
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            marginBottom: 3,
            display: "flex",
            justifyContent: "center", // Center the tabs horizontally
            backgroundColor: "#f4f4f4",
            borderRadius: 2,
            boxShadow: 2,
            "& .MuiTabs-flexContainer": {
              justifyContent: "center", // Ensure tabs themselves are centered
            },
          }}
        >
          {categories.map((category, index) => (
            <Tab label={category} key={index} />
          ))}
        </Tabs>

        {/* Products List based on selected category */}
        <Box sx={{ padding: 2 }}>
          <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold", marginBottom: 2 }}>
            {selectedCategory === "All" ? "All Products" : selectedCategory}
          </Typography>
          <Grid container spacing={4}>
            {filteredProducts.length === 0 ? (
              <Typography variant="h6" color="text.secondary" sx={{ textAlign: "center", marginTop: 3 }}>
                No products found.
              </Typography>
            ) : (
              filteredProducts.map((product) => (
                <Grid item xs={12} sm={6} md={4} key={product.id}>
                  <Card sx={{ maxWidth: 300, boxShadow: 3, transition: "transform 0.3s ease", "&:hover": { transform: "scale(1.05)" } }}>
                    <CardActionArea component={Link} to={`/product/${product.id}`}>
                      {/* Product Badges (New, Discounted, etc.) */}
                      {product.discount && (
                        <Box sx={{ position: "absolute", top: 10, left: 10, backgroundColor: "green", color: "white", padding: "5px", borderRadius: 2 }}>
                          <Typography variant="caption">SALE</Typography>
                        </Box>
                      )}
                      <CardMedia
                        component="img"
                        sx={{ minHeight: 200, objectFit: "cover" }}
                        image={product.images[0]}
                        alt={product.title}
                      />
                      <CardContent>
                        <Typography variant="h6" component="div" sx={{ fontWeight: "bold" }}>
                          {product.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ minHeight: 40 }}>
                          {product.description}
                        </Typography>
                        {/* Price with Discount (if any) */}
                        <Typography variant="h6" color="primary" sx={{ marginTop: 1 }}>
                          {product.discount ? (
                            <>
                              <span style={{ textDecoration: "line-through", marginRight: "10px" }}>
                                ₹{product.price}
                              </span>
                              <span style={{ color: "green" }}>
                                ₹{(product.price - (product.price * (parseInt(product.discount) / 100)))} (Discounted)
                              </span>
                            </>
                          ) : (
                            `₹${product.price}`
                          )}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))
            )}
          </Grid>
        </Box>

        {/* CTA Button */}
        <Box sx={{ textAlign: "center", marginTop: 4 }}>
          <Button variant="contained" color="primary" sx={{ padding: "10px 20px" }}>
            Shop Now
          </Button>
        </Box>
      </Box>
    </Layout>
  );
};

export default Products;



// const Products = () => {
//   const [selectedCategory, setSelectedCategory] = useState("Television"); // Default category
//   const [tabIndex, setTabIndex] = useState(0); // Tab index for mobile
//   const [searchQuery, setSearchQuery] = useState("");

//   // Get all unique categories from the products array
//   const categories = ["All", ...new Set(products.map((product) => product.category))];

//   // Filter products based on search query and category
//   // const filteredProducts = products
//   //   .filter((product) => {
//   //     if (selectedCategory === "All") {
//   //       // If "All" tab is selected, just filter by the search query
//   //       return product.title.toLowerCase().includes(searchQuery.toLowerCase());
//   //     } else {
//   //       // Otherwise, filter by both selected category and search query
//   //       return (
//   //         product.category === selectedCategory &&
//   //         product.title.toLowerCase().includes(searchQuery.toLowerCase())
//   //       );
//   //     }
//   //   });

//   const filteredProducts = products
//   .filter((product) => {
//     if (selectedCategory === "All") {
//       // If "All" tab is selected, return all products and apply the search query.
//       return (
//         product.title.toLowerCase().includes(searchQuery.toLowerCase())
//       );
//     } else {
//       // Otherwise, filter by both selected category and search query
//       return (
//         product.category === selectedCategory &&
//         product.title.toLowerCase().includes(searchQuery.toLowerCase())
//       );
//     }
//   });
//   // Handle tab change
//   const handleTabChange = (event, newValue) => {
//     setTabIndex(newValue);
//     setSelectedCategory(categories[newValue]); // Update the selected category when the tab changes
//   };

//   return (
//     <Layout>
//       <Box sx={{ m: 3 }}>
//         {/* Special Offers Section */}
//         <Box sx={{ backgroundColor: "#ff9800", padding: "10px 0", marginBottom: "20px", textAlign: "center" }}>
//           <Typography variant="h5" sx={{ color: "#fff" }}>
//             🛍️ Special Offer: Get 20% off on selected items! Limited time only! 🛍️
//           </Typography>
//         </Box>

//         {/* Search Bar */}
//         <Box sx={{ display: "flex", justifyContent: "center", marginBottom: 3 }}>
//           <TextField
//             variant="outlined"
//             placeholder="Search Products..."
//             size="small"
//             value={searchQuery}
//             onChange={(e) => setSearchQuery(e.target.value)}
//             InputProps={{
//               startAdornment: (
//                 <InputAdornment position="start">
//                   <IconButton>
//                     <SearchIcon />
//                   </IconButton>
//                 </InputAdornment>
//               ),
//             }}
//             sx={{ width: "50%" }}
//           />
//         </Box>

//         {/* Horizontal Tabs for mobile and desktop */}
//         <Tabs
//           value={tabIndex}
//           onChange={handleTabChange}
//           indicatorColor="primary"
//           textColor="primary"
//           variant="scrollable"
//           scrollButtons="auto"
//           sx={{
//             marginBottom: 3,
//             display: "flex",
//             justifyContent: "center", // Center the tabs horizontally
//             backgroundColor: "#f4f4f4",
//             borderRadius: 2,
//             boxShadow: 2,
//             "& .MuiTabs-flexContainer": {
//               justifyContent: "center", // Ensure tabs themselves are centered
//             },
//           }}
//         >
//           {categories.map((category, index) => (
//             <Tab label={category} key={index} />
//           ))}
//         </Tabs>

//         {/* Products List based on selected category */}
//         <Box sx={{ padding: 2 }}>
//           <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold", marginBottom: 2 }}>
//             {selectedCategory === "All" ? "All Products" : selectedCategory}
//           </Typography>
//           <Grid container spacing={4}>
//             {filteredProducts.map((product) => (
//               <Grid item xs={12} sm={6} md={4} key={product.id}>
//                 <Card sx={{ maxWidth: 300, boxShadow: 3, transition: "transform 0.3s ease", "&:hover": { transform: "scale(1.05)" } }}>
//                   <CardActionArea component={Link} to={`/product/${product.id}`}>
//                     {/* Product Badges (New, Discounted, etc.) */}
//                     {product.discount && (
//                       <Box sx={{ position: "absolute", top: 10, left: 10, backgroundColor: "green", color: "white", padding: "5px", borderRadius: 2 }}>
//                         <Typography variant="caption">SALE</Typography>
//                       </Box>
//                     )}
//                     <CardMedia
//                       component="img"
//                       sx={{ minHeight: 200, objectFit: "cover" }}
//                       image={product.images[0]}
//                       alt={product.title}
//                     />
//                     <CardContent>
//                       <Typography variant="h6" component="div" sx={{ fontWeight: "bold" }}>
//                         {product.title}
//                       </Typography>
//                       <Typography variant="body2" color="text.secondary" sx={{ minHeight: 40 }}>
//                         {product.description}
//                       </Typography>
//                       {/* Price with Discount (if any) */}
//                       <Typography variant="h6" color="primary" sx={{ marginTop: 1 }}>
//                         {product.discount ? (
//                           <>
//                             <span style={{ textDecoration: "line-through", marginRight: "10px" }}>
//                               ₹{product.price}
//                             </span>
//                             <span style={{ color: "green" }}>
                        
//                               ₹{(product.price - (product.price * (parseInt(product.discount) / 100)))} (Discounted)
//                             </span>
//                           </>
//                         ) : (
//                           `₹${product.price}`
//                         )}
//                       </Typography>
//                     </CardContent>
//                   </CardActionArea>
//                 </Card>
//               </Grid>
//             ))}
//           </Grid>
//         </Box>

//         {/* CTA Button */}
//         <Box sx={{ textAlign: "center", marginTop: 4 }}>
//           <Button variant="contained" color="primary" sx={{ padding: "10px 20px" }}>
//             Shop Now
//           </Button>
//         </Box>
//       </Box>
//     </Layout>
//   );
// };

// export default Products;
