import { Box, Grid, Typography, CircularProgress, Divider } from "@mui/material";
import { useEffect, useState } from "react";

const StatsSection = () => {
  const [progress, setProgress] = useState({
    customers: 0,
    products: 0,
    satisfaction: 0,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevState) => ({
        customers: Math.min(prevState.customers + 2, 100),
        products: Math.min(prevState.products + 1, 100),
        satisfaction: Math.min(prevState.satisfaction + 0.5, 98),
      }));
    }, 30);
    
    return () => clearInterval(interval);
  }, []);

  return (
    <Box sx={{ my: 10, backgroundColor: "#f4f4f4", p: 6, borderRadius: "8px" }}>
      <Typography variant="h5" sx={{ fontWeight: "bold", color: "#333", textAlign: "center", mb: 4 }}>
        Our Impact in Numbers
      </Typography>
      <Grid container spacing={4} sx={{ mt: 4 }} justifyContent="center" alignItems="center">
        {/* Happy Customers Stat */}
        <Grid item xs={12} sm={4}>
          <Box sx={{ textAlign: "center" }}>
            <CircularProgress
              variant="determinate"
              value={progress.customers}
              size={120}
              thickness={5}
              sx={{ color: "#ff6f61", marginBottom: 2 }}
            />
            <Typography variant="h4" sx={{ fontWeight: "bold", color: "#ff6f61" }}>
              {progress.customers}%
            </Typography>
            <Typography variant="body1" sx={{ color: "#555" }}>Happy Customers</Typography>
          </Box>
        </Grid>

        {/* Products Available Stat */}
        <Grid item xs={12} sm={4}>
          <Box sx={{ textAlign: "center" }}>
            <CircularProgress
              variant="determinate"
              value={progress.products}
              size={120}
              thickness={5}
              sx={{ color: "#4caf50", marginBottom: 2 }}
            />
            <Typography variant="h4" sx={{ fontWeight: "bold", color: "#4caf50" }}>
              {progress.products}+
            </Typography>
            <Typography variant="body1" sx={{ color: "#555" }}>Products Available</Typography>
          </Box>
        </Grid>

        {/* Customer Satisfaction Stat */}
        <Grid item xs={12} sm={4}>
          <Box sx={{ textAlign: "center" }}>
            <CircularProgress
              variant="determinate"
              value={progress.satisfaction}
              size={120}
              thickness={5}
              sx={{ color: "#2196f3", marginBottom: 2 }}
            />
            <Typography variant="h4" sx={{ fontWeight: "bold", color: "#2196f3" }}>
              {progress.satisfaction}%
            </Typography>
            <Typography variant="body1" sx={{ color: "#555" }}>Customer Satisfaction</Typography>
          </Box>
        </Grid>
      </Grid>

      {/* Divider for separation */}
      <Divider sx={{ my: 4 }} />

      {/* Additional Info */}
      <Box sx={{ textAlign: "center", mt: 2 }}>
        <Typography variant="body1" sx={{ color: "#555", maxWidth: "900px", margin: "0 auto" }}>
          We are proud of our growing customer base, the variety of products we offer, and our commitment to ensuring
          customer satisfaction. As we continue to grow, these numbers will only increase, thanks to your trust and support.
        </Typography>
      </Box>
    </Box>
  );
};

export default StatsSection;
