import React from 'react';
import { Box, IconButton, Badge } from '@mui/material';
import { FaShoppingCart } from 'react-icons/fa'; // Import ShoppingCart Icon from react-icons
import { useCart } from './CartContext'; // Import your Cart context to get the cart count

const FloatingCartButton = () => {
  const { cart } = useCart(); // Use the cart context to get the cart items

  // Calculate the total number of items in the cart by summing up the quantities
  const totalItemCount = cart.reduce((total, item) => total + item.quantity, 0);

  // If no items are in the cart, return null (hide the button)
  if (totalItemCount === 0) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: '120px', // Adjust position from the bottom
        right: '20px',  // Adjust position from the right
        zIndex: 1000,   // Ensure it's above other elements
      }}
    >
      <IconButton
        color="primary"
        component="a"
        href="/CartPage" // Link to the cart page
        rel="noopener noreferrer" // Security best practice
        sx={{
          backgroundColor: '#FF9800', // Cart button color
          padding: '15px',
          borderRadius: '50%',
          boxShadow: 3,
          '&:hover': {
            backgroundColor: '#FF5722', // Hover effect color
          }
        }}
      >
        <Badge
          badgeContent={totalItemCount} // Display the total number of items in the cart
          color="error"
          sx={{
            '& .MuiBadge-dot': {
              backgroundColor: '#FF5722', // Red dot color for cart count
            },
          }}
        >
          <FaShoppingCart size={30} color="white" />
        </Badge>
      </IconButton>
    </Box>
  );
};

export default FloatingCartButton;

// import React from 'react';
// import { Box, IconButton, Badge } from '@mui/material';
// import { FaShoppingCart } from 'react-icons/fa'; // Import ShoppingCart Icon from react-icons
// import { useCart } from './CartContext'; // Import your Cart context to get the cart count

// const FloatingCartButton = () => {
//   const { cart } = useCart(); // Use the cart context to get the cart items

//   // Calculate the total number of items in the cart by summing up the quantities
//   const totalItemCount = cart.reduce((total, item) => total + item.quantity, 0);

//   return (
//     <Box
//       sx={{
//         position: 'fixed',
//         bottom: '120px', // Adjust position from the bottom
//         right: '20px',  // Adjust position from the right
//         zIndex: 1000,   // Ensure it's above other elements
//       }}
//     >
//       <IconButton
//         color="primary"
//         component="a"
//         href="/CartPage" // Link to the cart page
//         rel="noopener noreferrer" // Security best practice
//         sx={{
//           backgroundColor: '#FF9800', // Cart button color
//           padding: '15px',
//           borderRadius: '50%',
//           boxShadow: 3,
//           '&:hover': {
//             backgroundColor: '#FF5722', // Hover effect color
//           }
//         }}
//       >
//         <Badge
//           badgeContent={totalItemCount} // Display the total number of items in the cart
//           color="error"
//           sx={{
//             '& .MuiBadge-dot': {
//               backgroundColor: '#FF5722', // Red dot color for cart count
//             },
//           }}
//         >
//           <FaShoppingCart size={30} color="white" />
//         </Badge>
//       </IconButton>
//     </Box>
//   );
// };

// export default FloatingCartButton;
