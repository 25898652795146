import React from "react";
import Layout from "../components/Layout/Layout";
import { Box, Typography, Grid, Card, CardContent, CardMedia, Button } from "@mui/material";
import { Fade } from "react-awesome-reveal"; // For smooth animations

// Replace with actual images of your digital signage screens in different locations
import adImage1 from "../images/banner4.jpg"; // Example image of digital signage in a shop
import adImage2 from "../images/banner2.jpg"; // Example image of digital signage in a cafe
import adImage3 from "../images/banner3.jpg"; // Example image of digital signage in a high-visibility area
import adBanner from "../images/ad_banner.jpg"; // Banner image for hero section
import MapWithCoordinates from "./MapWithCoordinates"; // Adjust path as necessary
import { coordinates } from "../data/coordinates";

const Ads = () => {

  return (
    <Layout>

      {/* Hero Section */}
      <Box
        sx={{
          backgroundImage: `url(${adBanner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "50vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
          textAlign: "center",
        }}
      >
        <Fade>
          <Typography variant="h3" sx={{ fontWeight: "bold", textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)" }}>
            Display Your Ads on Our Screens Across the City
          </Typography>
          <Typography variant="h6" sx={{ mt: 2, fontSize: "1.2rem", textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)" }}>
            Affordable and Impactful Advertising in High-Traffic Locations.
          </Typography>
        </Fade>
      </Box>

      {/* Main Content Section */}
      <Box sx={{ my: 10, px: 2, textAlign: "center" }}>
        <Fade direction="up" triggerOnce>
          <Typography variant="h4" sx={{ fontWeight: "bold", color: "#333", mb: 4 }}>
            Why Choose Our Digital Signage for Your Ads?
          </Typography>
        </Fade>

        <Grid container spacing={4} sx={{ justifyContent: "center" }}>
          <Grid item xs={12} sm={4}>
            <Fade direction="left" triggerOnce>
              <Card sx={{ boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)", borderRadius: "8px", overflow: "hidden" }}>
                <CardMedia component="img" height="200" image={adImage1} alt="Shop Digital Signage" />
                <CardContent>
                  <Typography variant="h6" sx={{ fontWeight: "bold", color: "#333", textTransform: "uppercase", mb: 2 }}>
                    Ads in Shops
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#555", fontSize: "1rem" }}>
                    Display your ads on screens installed in high-traffic shops across the city. Reach your target audience where they shop.
                  </Typography>
                </CardContent>
              </Card>
            </Fade>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Fade direction="up" triggerOnce>
              <Card sx={{ boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)", borderRadius: "8px", overflow: "hidden" }}>
                <CardMedia component="img" height="200" image={adImage2} alt="Cafe Digital Signage" />
                <CardContent>
                  <Typography variant="h6" sx={{ fontWeight: "bold", color: "#333", textTransform: "uppercase", mb: 2 }}>
                    Ads in Cafes
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#555", fontSize: "1rem" }}>
                    Take advantage of our screens installed in popular cafes. Your ads will be visible to customers as they relax and enjoy their time.
                  </Typography>
                </CardContent>
              </Card>
            </Fade>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Fade direction="right" triggerOnce>
              <Card sx={{ boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)", borderRadius: "8px", overflow: "hidden" }}>
                <CardMedia component="img" height="200" image={adImage3} alt="High Visibility Area Installations" />
                <CardContent>
                  <Typography variant="h6" sx={{ fontWeight: "bold", color: "#333", textTransform: "uppercase", mb: 2 }}>
                    High-Visibility Locations
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#555", fontSize: "1rem" }}>
                    Our screens are placed in high-traffic areas like shopping malls and busy streets, offering maximum exposure for your ads.
                  </Typography>
                </CardContent>
              </Card>
            </Fade>
          </Grid>
        </Grid>

        {/* Impact Section */}
        <Box sx={{ mt: 10, backgroundColor: "#f9f9f9", py: 6 }}>
          <Fade direction="up" triggerOnce>
            <Typography variant="h5" sx={{ fontWeight: "bold", color: "#333", mb: 4 }}>
              Our Reach in Numbers
            </Typography>
          </Fade>
          <Grid container spacing={4} sx={{ justifyContent: "center" }}>
            <Grid item xs={12} sm={3}>
              <Box sx={{ textAlign: "center" }}>
                <Typography variant="h4" sx={{ fontWeight: "bold", color: "#FF5733" }}>500+</Typography>
                <Typography variant="body1" sx={{ color: "#555" }}>Screens Installed</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box sx={{ textAlign: "center" }}>
                <Typography variant="h4" sx={{ fontWeight: "bold", color: "#FF5733" }}>100+</Typography>
                <Typography variant="body1" sx={{ color: "#555" }}>Locations Across the City</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box sx={{ textAlign: "center" }}>
                <Typography variant="h4" sx={{ fontWeight: "bold", color: "#FF5733" }}>98%</Typography>
                <Typography variant="body1" sx={{ color: "#555" }}>Customer Satisfaction</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* CTA Section for Map (Side-by-Side Layout) */}
        <Box sx={{ mt: 10, display: "flex", flexDirection: { xs: "column", sm: "row" }, justifyContent: "space-between", alignItems: "center", textAlign: { xs: "center", sm: "left" } }}>
          <Box sx={{ flex: 1, paddingRight: { sm: 2 } }}>
            <Typography variant="h4" sx={{ fontWeight: "bold", mb: 3 }}>
              See Ads in Your Area
            </Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
              Want to know where our screens are located? Check out the interactive map with all our high-traffic locations.
            </Typography>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#FF5733",
                color: "white",
                fontWeight: "bold",
                "&:hover": {
                  backgroundColor: "#f5f5f5",
                },
              }}
              href="#map"
            >
              View Locations on Map
            </Button>
          </Box>

          {/* Map Section */}
          {/* <Box sx={{ flex: 1, height: "400px", maxWidth: "100%" }}>
          </Box>
           */}

<Box
  sx={{
    width: "100%",
    height: "450px", // Ensure there's enough height for the map
    maxWidth: "1200px", // Optional: max width for the container
    margin: "0 auto", // Optional: center the map container
    borderRadius: "8px", // Optional: rounded corners
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Optional: box shadow
    position: "relative", // Make sure the position is set correctly
  }}
>
<MapWithCoordinates coordinates={coordinates} />
  
</Box>


        </Box>


        {/* Call to Action Section */}
        <Box sx={{ my: 10, textAlign: "center" }}>
          <Fade direction="up" triggerOnce>
            <Typography variant="h4" sx={{ fontWeight: "bold", color: "#333", mb: 3 }}>
              Ready to Display Your Ad?
            </Typography>
            <Typography variant="body1" sx={{ color: "#555", fontSize: "1rem", mb: 4 }}>
              Get in touch with us to start showcasing your ad on our screens. Let us help you increase your brand’s visibility at an affordable price.
            </Typography>
            <Button variant="contained" sx={{ backgroundColor: "#FF5733", color: "white", fontWeight: "bold" }}>
              Contact Us Now
            </Button>
          </Fade>
        </Box>
      </Box>
    </Layout>
  );
};

export default Ads;
