export const products1 = [
  {
    id: 1,
    title: "Smart LED TV",
    description:
      "A high-definition smart LED TV that offers superior picture quality with access to streaming platforms, gaming apps, and more.",
    images: [
      "../images/24s/1.jpeg",
      "https://via.placeholder.com/500x300?text=Smart+LED+TV+Image2",
      "https://via.placeholder.com/500x300?text=Smart+LED+TV+Image3",
    ],
    features: [
      "4K Resolution",
      "Smart Streaming Apps",
      "Built-in Speakers",
      "HDR Support",
    ],
    specifications: {
      "Screen Size": "55 inches",
      "Resolution": "3840 x 2160",
      "Ports": "3 HDMI, 2 USB",
      "Operating System": "Android TV",
      "Wi-Fi": "Yes",
    },
    price: 500,
    discount: "10%",
    category: "Television",
    link: "/smart-led-tv",
  },
  {
    id: 2,
    title: "Non-Smart LED TV",
    description:
      "A traditional LED TV offering high-definition resolution. Perfect for users who prefer simplicity.",
    images: [
      "https://via.placeholder.com/500x300?text=Non-Smart+LED+TV+Image1",
      "https://via.placeholder.com/500x300?text=Non-Smart+LED+TV+Image2",
    ],
    features: [
      "Full HD Resolution",
      "Energy Efficient",
      "Lightweight Design",
    ],
    specifications: {
      "Screen Size": "32 inches",
      "Resolution": "1920 x 1080",
      "Ports": "2 HDMI, 1 USB",
      "Sound": "Stereo",
    },
    price: 250,
    discount: "5%",
    category: "Television",
    link: "/non-smart-led-tv",
  },
  {
    id: 3,
    title: "Cooler",
    description:
      "An efficient air cooler with eco-friendly and energy-saving features for homes, offices, and outdoor spaces.",
    images: [
      "https://via.placeholder.com/500x300?text=Cooler+Image1",
      "https://via.placeholder.com/500x300?text=Cooler+Image2",
    ],
    features: [
      "Energy-Efficient",
      "Multiple Speed Settings",
      "Eco-Friendly",
      "Compact Design",
    ],
    specifications: {
      "Capacity": "40 Liters",
      "Power": "150 Watts",
      "Dimensions": "45 x 45 x 90 cm",
      "Cooling Area": "Up to 500 sq. ft.",
    },
    price: 150,
    discount: "15%",
    category: "Home Appliances",
    link: "/cooler",
  },
  {
    id: 4,
    title: "Static Signage Board",
    description:
      "A reliable, static LED signage board ideal for advertisements and promotions with high brightness for visibility.",
    images: [
      "https://via.placeholder.com/500x300?text=Signage+Board+Image1",
      "https://via.placeholder.com/500x300?text=Signage+Board+Image2",
    ],
    features: [
      "High Brightness",
      "Durable and Weather-Proof",
      "Perfect for Indoor and Outdoor Use",
      "Energy Efficient",
    ],
    specifications: {
      "Dimensions": "50 x 30 inches",
      "Brightness": "1200 NITS",
      "Power Consumption": "80W",
      "Material": "Aluminum Frame",
    },
    price: 300,
    discount: "12%",
    category: "Signage",
    link: "/static-signage-board",
  },
  {
    id: 5,
    title: "32-inch LED TV",
    description:
      "A compact LED TV with Full HD resolution, ideal for smaller rooms or as a second TV. Offers great picture quality and energy efficiency.",
    images: [
      "https://via.placeholder.com/500x300?text=32-inch+LED+TV+Image1",
      "https://via.placeholder.com/500x300?text=32-inch+LED+TV+Image2",
    ],
    features: [
      "Full HD Resolution",
      "Energy Efficient",
      "Compact Size",
      "Slim Design",
    ],
    specifications: {
      "Screen Size": "32 inches",
      "Resolution": "1920 x 1080",
      "Ports": "2 HDMI, 1 USB",
      "Sound": "Stereo",
    },
    price: 180,
    discount: "8%",
    category: "Television",
    link: "/32-inch-led-tv",
  },
  {
    id: 6,
    title: "24-inch LED TV",
    description:
      "A small, budget-friendly LED TV, perfect for bedrooms, kitchens, or offices. Enjoy Full HD content with clear picture quality.",
    images: [
      "https://via.placeholder.com/500x300?text=24-inch+LED+TV+Image1",
      "https://via.placeholder.com/500x300?text=24-inch+LED+TV+Image2",
    ],
    features: [
      "Full HD Resolution",
      "Space-Saving Design",
      "Budget-Friendly",
      "Lightweight",
    ],
    specifications: {
      "Screen Size": "24 inches",
      "Resolution": "1920 x 1080",
      "Ports": "1 HDMI, 1 USB",
      "Sound": "Stereo",
    },
    price: 130,
    discount: "10%",
    category: "Television",
    link: "/24-inch-led-tv",
  },
  {
    id: 7,
    title: "43-inch LED TV",
    description:
      "A mid-size LED TV offering Full HD resolution and a sleek, modern design. Perfect for the living room or larger spaces.",
    images: [
      "https://via.placeholder.com/500x300?text=43-inch+LED+TV+Image1",
      "https://via.placeholder.com/500x300?text=43-inch+LED+TV+Image2",
    ],
    features: [
      "Full HD Resolution",
      "Slim Design",
      "Energy Efficient",
      "Enhanced Sound Quality",
    ],
    specifications: {
      "Screen Size": "43 inches",
      "Resolution": "1920 x 1080",
      "Ports": "3 HDMI, 2 USB",
      "Sound": "Stereo",
    },
    price: 350,
    discount: "6%",
    category: "Television",
    link: "/43-inch-led-tv",
  },
];

export const products_bk = [
  // {
  //   id: 1,
  //   title: "Ceiling Fan",
  //   description:
  //     "A sleek and efficient ceiling fan that provides a cool breeze and energy savings for your room.",
  //   images: [
  //     "../images/ceiling-fan-1.jpeg",
  //     "https://via.placeholder.com/500x300?text=Ceiling+Fan+Image2",
  //     "https://via.placeholder.com/500x300?text=Ceiling+Fan+Image3",
  //   ],
  //   features: [
  //     "High Airflow",
  //     "Energy Efficient",
  //     "3 Speed Settings",
  //     "Remote Control",
  //   ],
  //   specifications: {
  //     "Size": "120 cm",
  //     "Speed": "300 RPM",
  //     "Warranty": "2 Years",
  //     "Energy Rating": "5 Star",
  //   },
  //   price: 80,
  //   discount: "15%",
  //   category: "Ceiling Fan",
  //   link: "/ceiling-fan",
  // },
  // {
  //   id: 2,
  //   title: "Table Fan",
  //   description:
  //     "A portable and compact table fan, perfect for keeping your space cool and comfortable.",
  //   images: [
  //     "../images/table-fan-1.jpeg",
  //     "https://via.placeholder.com/500x300?text=Table+Fan+Image2",
  //     "https://via.placeholder.com/500x300?text=Table+Fan+Image3",
  //   ],
  //   features: [
  //     "Adjustable Height",
  //     "3 Speed Settings",
  //     "Oscillation Function",
  //     "Quiet Operation",
  //   ],
  //   specifications: {
  //     "Size": "16 inches",
  //     "Speed": "2000 RPM",
  //     "Power": "50W",
  //     "Warranty": "1 Year",
  //   },
  //   price: 25,
  //   discount: "10%",
  //   category: "Table Fan",
  //   link: "/table-fan",
  // },
  // {
  //   id: 3,
  //   title: "Ap Fan",
  //   description:
  //     "An air purifier fan that combines the power of a fan and purifier to create a cleaner, cooler environment.",
  //   images: [
  //     "../images/ap-fan-1.jpeg",
  //     "https://via.placeholder.com/500x300?text=Ap+Fan+Image2",
  //     "https://via.placeholder.com/500x300?text=Ap+Fan+Image3",
  //   ],
  //   features: [
  //     "Air Purification",
  //     "Multiple Speed Settings",
  //     "Quiet Operation",
  //     "Compact Design",
  //   ],
  //   specifications: {
  //     "Size": "18 inches",
  //     "Power": "75W",
  //     "Filter Type": "HEPA Filter",
  //     "Warranty": "1 Year",
  //   },
  //   price: 70,
  //   discount: "20%",
  //   category: "Ap Fan",
  //   link: "/ap-fan",
  // },
  {
    id: 4,
    title: "Cooler",
    description:
      "A room cooler that provides a refreshing breeze and cool air during the hot summer months.",
    images: [
      "../images/cooler/1.jpeg"
    ],
    features: [
      "Eco-friendly",
      "Low Power Consumption",
      "Adjustable Air Flow",
      "Water Tank Capacity: 30L",
    ],
    specifications: {
      "Size": "Large",
      "Cooling Area": "250 sq. ft.",
      "Power": "150W",
      "Warranty": "1 Year",
    },
    price: 150,
    discount: "25%",
    category: "Cooler",
    link: "/cooler",
  },
  // {
  //   id: 5,
  //   title: "Electric Iron",
  //   description:
  //     "A powerful electric iron with a non-stick soleplate and steam functions for efficient ironing.",
  //   images: [
  //     "../images/electric-iron-1.jpeg",
  //     "https://via.placeholder.com/500x300?text=Electric+Iron+Image2",
  //     "https://via.placeholder.com/500x300?text=Electric+Iron+Image3",
  //   ],
  //   features: [
  //     "Non-Stick Soleplate",
  //     "Vertical Steam Function",
  //     "Temperature Control",
  //     "Steam Burst",
  //   ],
  //   specifications: {
  //     "Power": "1200W",
  //     "Water Tank Capacity": "200ml",
  //     "Warranty": "2 Years",
  //   },
  //   price: 30,
  //   discount: "10%",
  //   category: "Electric Iron",
  //   link: "/electric-iron",
  // },
  // {
  //   id: 6,
  //   title: "Juicer Mixer Grinder",
  //   description:
  //     "A versatile juicer mixer grinder for all your blending, grinding, and juicing needs.",
  //   images: [
  //     "../images/juicer-mixer-grinder-1.jpeg",
  //     "https://via.placeholder.com/500x300?text=Juicer+Mixer+Grinder+Image2",
  //     "https://via.placeholder.com/500x300?text=Juicer+Mixer+Grinder+Image3",
  //   ],
  //   features: [
  //     "3 Jars with Different Capacities",
  //     "Heavy Duty Motor",
  //     "Juicing and Grinding",
  //     "Multiple Speed Settings",
  //   ],
  //   specifications: {
  //     "Power": "500W",
  //     "Warranty": "2 Years",
  //     "Number of Jars": "3",
  //   },
  //   price: 50,
  //   discount: "15%",
  //   category: "Juicer Mixer Grinder",
  //   link: "/juicer-mixer-grinder",
  // },
  // {
  //   id: 7,
  //   title: "Room Heater",
  //   description:
  //     "A room heater designed to keep you warm and cozy during the chilly winter nights.",
  //   images: [
  //     "../images/room-heater-1.jpeg",
  //     "https://via.placeholder.com/500x300?text=Room+Heater+Image2",
  //     "https://via.placeholder.com/500x300?text=Room+Heater+Image3",
  //   ],
  //   features: [
  //     "Adjustable Thermostat",
  //     "Overheat Protection",
  //     "Portable Design",
  //     "Quiet Operation",
  //   ],
  //   specifications: {
  //     "Power": "2000W",
  //     "Warranty": "1 Year",
  //     "Heating Area": "150 sq. ft.",
  //   },
  //   price: 70,
  //   discount: "10%",
  //   category: "Room Heater",
  //   link: "/room-heater",
  // },
  // {
  //   id: 8,
  //   title: "Immersion Rod",
  //   description:
  //     "A high-quality immersion rod for quickly heating water in your bathroom or kitchen.",
  //   images: [
  //     "../images/immersion-rod-1.jpeg",
  //     "https://via.placeholder.com/500x300?text=Immersion+Rod+Image2",
  //     "https://via.placeholder.com/500x300?text=Immersion+Rod+Image3",
  //   ],
  //   features: [
  //     "Fast Heating",
  //     "Shockproof Design",
  //     "Thermostatic Control",
  //     "Rust Resistant",
  //   ],
  //   specifications: {
  //     "Power": "1500W",
  //     "Warranty": "1 Year",
  //   },
  //   price: 15,
  //   discount: "5%",
  //   category: "Immersion Rod",
  //   link: "/immersion-rod",
  // },
  // {
  //   id: 9,
  //   title: "Electric Geyser",
  //   description:
  //     "A high-efficiency electric geyser designed to provide continuous hot water.",
  //   images: [
  //     "../images/electric-geyser-1.jpeg",
  //     "https://via.placeholder.com/500x300?text=Electric+Geyser+Image2",
  //     "https://via.placeholder.com/500x300?text=Electric+Geyser+Image3",
  //   ],
  //   features: [
  //     "High-Efficiency Heating",
  //     "Multiple Temperature Settings",
  //     "Rust Resistant Tank",
  //     "Auto Shut Off",
  //   ],
  //   specifications: {
  //     "Capacity": "25L",
  //     "Power": "2000W",
  //     "Warranty": "3 Years",
  //   },
  //   price: 100,
  //   discount: "20%",
  //   category: "Electric Geyser",
  //   link: "/electric-geyser",
  // },
  // {
  //   id: 10,
  //   title: "Electric Kettle",
  //   description:
  //     "A fast-boiling electric kettle with automatic shut-off and safety features.",
  //   images: [
  //     "../images/electric-kettle-1.jpeg",
  //     "https://via.placeholder.com/500x300?text=Electric+Kettle+Image2",
  //     "https://via.placeholder.com/500x300?text=Electric+Kettle+Image3",
  //   ],
  //   features: [
  //     "Rapid Boiling",
  //     "Auto Shut Off",
  //     "Cordless Design",
  //     "Boil Dry Protection",
  //   ],
  //   specifications: {
  //     "Capacity": "1.5L",
  //     "Power": "1500W",
  //     "Warranty": "1 Year",
  //   },
  //   price: 25,
  //   discount: "10%",
  //   category: "Electric Kettle",
  //   link: "/electric-kettle",
  // },
  // {
  //   id: 11,
  //   title: "Induction Cooker",
  //   description:
  //     "A modern induction cooker that offers fast and efficient cooking with precise temperature control.",
  //   images: [
  //     "../images/induction-cooker-1.jpeg",
  //     "https://via.placeholder.com/500x300?text=Induction+Cooker+Image2",
  //     "https://via.placeholder.com/500x300?text=Induction+Cooker+Image3",
  //   ],
  //   features: [
  //     "Energy Efficient",
  //     "Precise Temperature Control",
  //     "Touch Control",
  //     "Portable Design",
  //   ],
  //   specifications: {
  //     "Power": "2000W",
  //     "Warranty": "2 Years",
  //   },
  //   price: 60,
  //   discount: "15%",
  //   category: "Induction",
  //   link: "/induction-cooker",
  // },
  // {
  //   "id": 12,
  //   "title": "Cooler",
  //   "description": "A room cooler that provides a refreshing breeze and cool air during the hot summer months.",
  //   "images": [
  //     "../images/cooler/1.jpeg"
  //   ],
  //   "features": [
  //     "Eco-friendly",
  //     "Low Power Consumption",
  //     "Adjustable Air Flow",
  //     "Water Tank Capacity: 30L"
  //   ],
  //   "specifications": {
  //     "Size": "Large",
  //     "Cooling Area": "250 sq. ft.",
  //     "Power": "150W",
  //     "Warranty": "1 Year"
  //   },
  //   "price": 150,
  //   "discount": "25%",
  //   "category": "Cooler",
  //   "link": "/cooler"
  // },
  {
    "id": 13,
    "title": "Cooler",
    "description": "A room cooler that provides a refreshing breeze and cool air during the hot summer months.",
    "images": [
      "../images/cooler/2.jpeg"
    ],
    "features": [
      "Eco-friendly",
      "Low Power Consumption",
      "Adjustable Air Flow",
      "Water Tank Capacity: 30L"
    ],
    "specifications": {
      "Size": "Large",
      "Cooling Area": "250 sq. ft.",
      "Power": "150W",
      "Warranty": "1 Year"
    },
    "price": 150,
    "discount": "25%",
    "category": "Cooler",
    "link": "/cooler"
  },
  {
    "id": 14,
    "title": "Cooler",
    "description": "A room cooler that provides a refreshing breeze and cool air during the hot summer months.",
    "images": [
      "../images/cooler/3.jpeg"
    ],
    "features": [
      "Eco-friendly",
      "Low Power Consumption",
      "Adjustable Air Flow",
      "Water Tank Capacity: 30L"
    ],
    "specifications": {
      "Size": "Large",
      "Cooling Area": "250 sq. ft.",
      "Power": "150W",
      "Warranty": "1 Year"
    },
    "price": 150,
    "discount": "25%",
    "category": "Cooler",
    "link": "/cooler"
  },
  {
    "id": 15,
    "title": "Cooler",
    "description": "A room cooler that provides a refreshing breeze and cool air during the hot summer months.",
    "images": [
      "../images/cooler/4.jpeg"
    ],
    "features": [
      "Eco-friendly",
      "Low Power Consumption",
      "Adjustable Air Flow",
      "Water Tank Capacity: 30L"
    ],
    "specifications": {
      "Size": "Large",
      "Cooling Area": "250 sq. ft.",
      "Power": "150W",
      "Warranty": "1 Year"
    },
    "price": 150,
    "discount": "25%",
    "category": "Cooler",
    "link": "/cooler"
  },
  {
    "id": 16,
    "title": "Cooler",
    "description": "A room cooler that provides a refreshing breeze and cool air during the hot summer months.",
    "images": [
      "../images/cooler/5.jpeg"
    ],
    "features": [
      "Eco-friendly",
      "Low Power Consumption",
      "Adjustable Air Flow",
      "Water Tank Capacity: 30L"
    ],
    "specifications": {
      "Size": "Large",
      "Cooling Area": "250 sq. ft.",
      "Power": "150W",
      "Warranty": "1 Year"
    },
    "price": 150,
    "discount": "25%",
    "category": "Cooler",
    "link": "/cooler"
  },
  {
    "id": 17,
    "title": "Cooler",
    "description": "A room cooler that provides a refreshing breeze and cool air during the hot summer months.",
    "images": [
      "../images/cooler/6.jpeg"
    ],
    "features": [
      "Eco-friendly",
      "Low Power Consumption",
      "Adjustable Air Flow",
      "Water Tank Capacity: 30L"
    ],
    "specifications": {
      "Size": "Large",
      "Cooling Area": "250 sq. ft.",
      "Power": "150W",
      "Warranty": "1 Year"
    },
    "price": 150,
    "discount": "25%",
    "category": "Cooler",
    "link": "/cooler"
  },
  {
    "id": 18,
    "title": "Cooler",
    "description": "A room cooler that provides a refreshing breeze and cool air during the hot summer months.",
    "images": [
      "../images/cooler/7.jpeg"
    ],
    "features": [
      "Eco-friendly",
      "Low Power Consumption",
      "Adjustable Air Flow",
      "Water Tank Capacity: 30L"
    ],
    "specifications": {
      "Size": "Large",
      "Cooling Area": "250 sq. ft.",
      "Power": "150W",
      "Warranty": "1 Year"
    },
    "price": 150,
    "discount": "25%",
    "category": "Cooler",
    "link": "/cooler"
  },
  {
    "id": 19,
    "title": "Cooler",
    "description": "A room cooler that provides a refreshing breeze and cool air during the hot summer months.",
    "images": [
      "../images/cooler/8.jpeg"
    ],
    "features": [
      "Eco-friendly",
      "Low Power Consumption",
      "Adjustable Air Flow",
      "Water Tank Capacity: 30L"
    ],
    "specifications": {
      "Size": "Large",
      "Cooling Area": "250 sq. ft.",
      "Power": "150W",
      "Warranty": "1 Year"
    },
    "price": 150,
    "discount": "25%",
    "category": "Cooler",
    "link": "/cooler"
  },
  {
    "id": 20,
    "title": "Cooler",
    "description": "A room cooler that provides a refreshing breeze and cool air during the hot summer months.",
    "images": [
      "../images/cooler/9.jpeg"
    ],
    "features": [
      "Eco-friendly",
      "Low Power Consumption",
      "Adjustable Air Flow",
      "Water Tank Capacity: 30L"
    ],
    "specifications": {
      "Size": "Large",
      "Cooling Area": "250 sq. ft.",
      "Power": "150W",
      "Warranty": "1 Year"
    },
    "price": 150,
    "discount": "25%",
    "category": "Cooler",
    "link": "/cooler"
  },
  {
    "id": 21,
    "title": "AC",
    "description": "A high-performance air conditioner that ensures your room stays cool and comfortable during the hot summer months.",
    "images": [
      "../images/ac/1.jpeg"
    ],
    "features": [
      "Energy Efficient",
      "Remote Control",
      "Multi-Stage Filtration",
      "Silent Operation"
    ],
    "specifications": {
      "Cooling Capacity": "1.5 Ton",
      "Energy Rating": "5 Star",
      "Power Consumption": "1500W",
      "Warranty": "1 Year"
    },
    "price": 350,
    "discount": "15%",
    "category": "AC",
    "link": "/ac"
  },
  {
    "id": 22,
    "title": "AC",
    "description": "A high-performance air conditioner that ensures your room stays cool and comfortable during the hot summer months.",
    "images": [
      "../images/ac/2.jpeg"
    ],
    "features": [
      "Energy Efficient",
      "Remote Control",
      "Multi-Stage Filtration",
      "Silent Operation"
    ],
    "specifications": {
      "Cooling Capacity": "1.5 Ton",
      "Energy Rating": "5 Star",
      "Power Consumption": "1500W",
      "Warranty": "1 Year"
    },
    "price": 350,
    "discount": "15%",
    "category": "AC",
    "link": "/ac"
  },
  {
    "id": 23,
    "title": "AC",
    "description": "A high-performance air conditioner that ensures your room stays cool and comfortable during the hot summer months.",
    "images": [
      "../images/ac/3.jpeg"
    ],
    "features": [
      "Energy Efficient",
      "Remote Control",
      "Multi-Stage Filtration",
      "Silent Operation"
    ],
    "specifications": {
      "Cooling Capacity": "1.5 Ton",
      "Energy Rating": "5 Star",
      "Power Consumption": "1500W",
      "Warranty": "1 Year"
    },
    "price": 350,
    "discount": "15%",
    "category": "AC",
    "link": "/ac"
  },
  {
    "id": 24,
    "title": "Washing Machine",
    "description": "A powerful washing machine that ensures your clothes are cleaned effectively while saving water and energy.",
    "images": [
      "../images/washing_machine/1.jpeg"
    ],
    "features": [
      "Front Loading",
      "Multiple Wash Programs",
      "Quick Wash Mode",
      "Energy Efficient"
    ],
    "specifications": {
      "Capacity": "7 kg",
      "Type": "Front Load",
      "Spin Speed": "1200 RPM",
      "Warranty": "2 Years"
    },
    "price": 250,
    "discount": "20%",
    "category": "Washing Machine",
    "link": "/washing-machine"
  },
  {
    "id": 25,
    "title": "Washing Machine",
    "description": "A powerful washing machine that ensures your clothes are cleaned effectively while saving water and energy.",
    "images": [
      "../images/washing_machine/2.jpeg"
    ],
    "features": [
      "Front Loading",
      "Multiple Wash Programs",
      "Quick Wash Mode",
      "Energy Efficient"
    ],
    "specifications": {
      "Capacity": "7 kg",
      "Type": "Front Load",
      "Spin Speed": "1200 RPM",
      "Warranty": "2 Years"
    },
    "price": 250,
    "discount": "20%",
    "category": "Washing Machine",
    "link": "/washing-machine"
  },
  {
    "id": 26,
    "title": "Washing Machine",
    "description": "A powerful washing machine that ensures your clothes are cleaned effectively while saving water and energy.",
    "images": [
      "../images/washing_machine/3.jpeg"
    ],
    "features": [
      "Front Loading",
      "Multiple Wash Programs",
      "Quick Wash Mode",
      "Energy Efficient"
    ],
    "specifications": {
      "Capacity": "7 kg",
      "Type": "Front Load",
      "Spin Speed": "1200 RPM",
      "Warranty": "2 Years"
    },
    "price": 250,
    "discount": "20%",
    "category": "Washing Machine",
    "link": "/washing-machine"
  },
  {
    "id": 27,
    "title": "24'' LED TV",
    "description": "A high-definition LED TV that brings your entertainment to life with vibrant colors and crystal-clear images.",
    "images": [
      "../images/24s/1.jpeg",
      "../images/24s/2.jpeg",
      "../images/24s/3.jpeg",
      "../images/24s/4.jpeg",
      "../images/24s/5.jpeg",
      "../images/24s/6.jpeg"
    ],
    "features": [
      "4K Resolution",
      "Smart TV Features",
      "HDR Support",
      "Built-in Wi-Fi"
    ],
    "specifications": {
      "Size": "24 inches",
      "Resolution": "FHD",
      "Smart TV": "Yes",
      "Warranty": "1 Year"
    },
    "price": 600,
    "discount": "10%",
    "category": "LED TV",
    "link": "/led-tv"
  },
  {
    "id": 28,
    "title": "32'' LED TV",
    "description": "A high-definition LED TV that brings your entertainment to life with vibrant colors and crystal-clear images.",
    "images": [
      "../images/32s/1.jpeg",
      "../images/32s/2.jpeg",
      "../images/32s/3.jpeg",
      "../images/32s/4.jpeg",
      
    ],
    "features": [
      "4K Resolution",
      "Smart TV Features",
      "HDR Support",
      "Built-in Wi-Fi"
    ],
    "specifications": {
      "Size": "32 inches",
      "Resolution": "4K UHD",
      "Smart TV": "Yes",
      "Warranty": "1 Year"
    },
    "price": 600,
    "discount": "10%",
    "category": "LED TV",
    "link": "/led-tv"
  },
  {
    "id": 29,
    "title": "43'' LED TV",
    "description": "A high-definition LED TV that brings your entertainment to life with vibrant colors and crystal-clear images.",
    "images": [
      "../images/43s/1.jpeg",
      "../images/43s/2.jpeg",
      "../images/43s/3.jpeg",
      "../images/43s/4.jpeg",
      
    ],
    "features": [
      "4K Resolution",
      "Smart TV Features",
      "HDR Support",
      "Built-in Wi-Fi"
    ],
    "specifications": {
      "Size": "43 inches",
      "Resolution": "4K UHD",
      "Smart TV": "Yes",
      "Warranty": "1 Year"
    },
    "price": 600,
    "discount": "10%",
    "category": "LED TV",
    "link": "/led-tv"
  },
  {
    "id": 30,
    "title": "Speaker",
    "description": "A high-definition LED TV that brings your entertainment to life with vibrant colors and crystal-clear images.",
    "images": [
      "../images/speaker/1.jpeg",
      "../images/speaker/2.jpeg",
      "../images/speaker/3.jpeg",
      "../images/speaker/4.jpeg",
      
    ],
    "features": [
      "4K Resolution",
      "Smart TV Features",
      "HDR Support",
      "Built-in Wi-Fi"
    ],
    "specifications": {
      "Size": "43 inches",
      "Resolution": "4K UHD",
      "Smart TV": "Yes",
      "Warranty": "1 Year"
    },
    "price": 600,
    "discount": "10%",
    "category": "Speaker",
    "link": "/speaker"
  }


];


export const products_old = [
  {
    id: 4,
    title: "Cooler",
    description:
      "A room cooler that provides a refreshing breeze and cool air during the hot summer months.",
    images: [
      "../images/cooler/1.jpeg"
    ],
    features: [
      "Eco-friendly",
      "Low Power Consumption",
      "Adjustable Air Flow",
      "Water Tank Capacity: 30L",
    ],
    specifications: {
      "Size": "Large",
      "Cooling Area": "250 sq. ft.",
      "Power": "150W",
      "Warranty": "1 Year",
    },
    price: 150,
    discount: "25%",
    category: "Cooler",
    link: "/cooler",
  },
  {
    "id": 13,
    "title": "Cooler",
    "description": "A room cooler that provides a refreshing breeze and cool air during the hot summer months.",
    "images": [
      "../images/cooler/2.jpeg"
    ],
    "features": [
      "Eco-friendly",
      "Low Power Consumption",
      "Adjustable Air Flow",
      "Water Tank Capacity: 30L"
    ],
    "specifications": {
      "Size": "Large",
      "Cooling Area": "250 sq. ft.",
      "Power": "150W",
      "Warranty": "1 Year"
    },
    "price": 150,
    "discount": "25%",
    "category": "Cooler",
    "link": "/cooler"
  },
  {
    "id": 14,
    "title": "Cooler",
    "description": "A room cooler that provides a refreshing breeze and cool air during the hot summer months.",
    "images": [
      "../images/cooler/3.jpeg"
    ],
    "features": [
      "Eco-friendly",
      "Low Power Consumption",
      "Adjustable Air Flow",
      "Water Tank Capacity: 30L"
    ],
    "specifications": {
      "Size": "Large",
      "Cooling Area": "250 sq. ft.",
      "Power": "150W",
      "Warranty": "1 Year"
    },
    "price": 150,
    "discount": "25%",
    "category": "Cooler",
    "link": "/cooler"
  },
  {
    "id": 15,
    "title": "Cooler",
    "description": "A room cooler that provides a refreshing breeze and cool air during the hot summer months.",
    "images": [
      "../images/cooler/4.jpeg"
    ],
    "features": [
      "Eco-friendly",
      "Low Power Consumption",
      "Adjustable Air Flow",
      "Water Tank Capacity: 30L"
    ],
    "specifications": {
      "Size": "Large",
      "Cooling Area": "250 sq. ft.",
      "Power": "150W",
      "Warranty": "1 Year"
    },
    "price": 150,
    "discount": "25%",
    "category": "Cooler",
    "link": "/cooler"
  },
  {
    "id": 16,
    "title": "Cooler",
    "description": "A room cooler that provides a refreshing breeze and cool air during the hot summer months.",
    "images": [
      "../images/cooler/5.jpeg"
    ],
    "features": [
      "Eco-friendly",
      "Low Power Consumption",
      "Adjustable Air Flow",
      "Water Tank Capacity: 30L"
    ],
    "specifications": {
      "Size": "Large",
      "Cooling Area": "250 sq. ft.",
      "Power": "150W",
      "Warranty": "1 Year"
    },
    "price": 150,
    "discount": "25%",
    "category": "Cooler",
    "link": "/cooler"
  },
  {
    "id": 17,
    "title": "Cooler",
    "description": "A room cooler that provides a refreshing breeze and cool air during the hot summer months.",
    "images": [
      "../images/cooler/6.jpeg"
    ],
    "features": [
      "Eco-friendly",
      "Low Power Consumption",
      "Adjustable Air Flow",
      "Water Tank Capacity: 30L"
    ],
    "specifications": {
      "Size": "Large",
      "Cooling Area": "250 sq. ft.",
      "Power": "150W",
      "Warranty": "1 Year"
    },
    "price": 150,
    "discount": "25%",
    "category": "Cooler",
    "link": "/cooler"
  },
  {
    "id": 18,
    "title": "Cooler",
    "description": "A room cooler that provides a refreshing breeze and cool air during the hot summer months.",
    "images": [
      "../images/cooler/7.jpeg"
    ],
    "features": [
      "Eco-friendly",
      "Low Power Consumption",
      "Adjustable Air Flow",
      "Water Tank Capacity: 30L"
    ],
    "specifications": {
      "Size": "Large",
      "Cooling Area": "250 sq. ft.",
      "Power": "150W",
      "Warranty": "1 Year"
    },
    "price": 150,
    "discount": "25%",
    "category": "Cooler",
    "link": "/cooler"
  },
  {
    "id": 19,
    "title": "Cooler",
    "description": "A room cooler that provides a refreshing breeze and cool air during the hot summer months.",
    "images": [
      "../images/cooler/8.jpeg"
    ],
    "features": [
      "Eco-friendly",
      "Low Power Consumption",
      "Adjustable Air Flow",
      "Water Tank Capacity: 30L"
    ],
    "specifications": {
      "Size": "Large",
      "Cooling Area": "250 sq. ft.",
      "Power": "150W",
      "Warranty": "1 Year"
    },
    "price": 150,
    "discount": "25%",
    "category": "Cooler",
    "link": "/cooler"
  },
  {
    "id": 20,
    "title": "Cooler",
    "description": "A room cooler that provides a refreshing breeze and cool air during the hot summer months.",
    "images": [
      "../images/cooler/9.jpeg"
    ],
    "features": [
      "Eco-friendly",
      "Low Power Consumption",
      "Adjustable Air Flow",
      "Water Tank Capacity: 30L"
    ],
    "specifications": {
      "Size": "Large",
      "Cooling Area": "250 sq. ft.",
      "Power": "150W",
      "Warranty": "1 Year"
    },
    "price": 150,
    "discount": "25%",
    "category": "Cooler",
    "link": "/cooler"
  },
  {
    "id": 21,
    "title": "AC",
    "description": "A high-performance air conditioner that ensures your room stays cool and comfortable during the hot summer months.",
    "images": [
      "../images/ac/1.jpeg"
    ],
    "features": [
      "Energy Efficient",
      "Remote Control",
      "Multi-Stage Filtration",
      "Silent Operation"
    ],
    "specifications": {
      "Cooling Capacity": "1.5 Ton",
      "Energy Rating": "5 Star",
      "Power Consumption": "1500W",
      "Warranty": "1 Year"
    },
    "price": 350,
    "discount": "15%",
    "category": "AC",
    "link": "/ac"
  },
  {
    "id": 22,
    "title": "AC",
    "description": "A high-performance air conditioner that ensures your room stays cool and comfortable during the hot summer months.",
    "images": [
      "../images/ac/2.jpeg"
    ],
    "features": [
      "Energy Efficient",
      "Remote Control",
      "Multi-Stage Filtration",
      "Silent Operation"
    ],
    "specifications": {
      "Cooling Capacity": "1.5 Ton",
      "Energy Rating": "5 Star",
      "Power Consumption": "1500W",
      "Warranty": "1 Year"
    },
    "price": 350,
    "discount": "15%",
    "category": "AC",
    "link": "/ac"
  },
  {
    "id": 23,
    "title": "AC",
    "description": "A high-performance air conditioner that ensures your room stays cool and comfortable during the hot summer months.",
    "images": [
      "../images/ac/3.jpeg"
    ],
    "features": [
      "Energy Efficient",
      "Remote Control",
      "Multi-Stage Filtration",
      "Silent Operation"
    ],
    "specifications": {
      "Cooling Capacity": "1.5 Ton",
      "Energy Rating": "5 Star",
      "Power Consumption": "1500W",
      "Warranty": "1 Year"
    },
    "price": 350,
    "discount": "15%",
    "category": "AC",
    "link": "/ac"
  },
  {
    "id": 24,
    "title": "Washing Machine",
    "description": "A powerful washing machine that ensures your clothes are cleaned effectively while saving water and energy.",
    "images": [
      "../images/washing_machine/1.jpeg"
    ],
    "features": [
      "Front Loading",
      "Multiple Wash Programs",
      "Quick Wash Mode",
      "Energy Efficient"
    ],
    "specifications": {
      "Capacity": "7 kg",
      "Type": "Front Load",
      "Spin Speed": "1200 RPM",
      "Warranty": "2 Years"
    },
    "price": 250,
    "discount": "20%",
    "category": "Washing Machine",
    "link": "/washing-machine"
  },
  {
    "id": 25,
    "title": "Washing Machine",
    "description": "A powerful washing machine that ensures your clothes are cleaned effectively while saving water and energy.",
    "images": [
      "../images/washing_machine/2.jpeg"
    ],
    "features": [
      "Front Loading",
      "Multiple Wash Programs",
      "Quick Wash Mode",
      "Energy Efficient"
    ],
    "specifications": {
      "Capacity": "7 kg",
      "Type": "Front Load",
      "Spin Speed": "1200 RPM",
      "Warranty": "2 Years"
    },
    "price": 250,
    "discount": "20%",
    "category": "Washing Machine",
    "link": "/washing-machine"
  },
  {
    "id": 26,
    "title": "Washing Machine",
    "description": "A powerful washing machine that ensures your clothes are cleaned effectively while saving water and energy.",
    "images": [
      "../images/washing_machine/3.jpeg"
    ],
    "features": [
      "Front Loading",
      "Multiple Wash Programs",
      "Quick Wash Mode",
      "Energy Efficient"
    ],
    "specifications": {
      "Capacity": "7 kg",
      "Type": "Front Load",
      "Spin Speed": "1200 RPM",
      "Warranty": "2 Years"
    },
    "price": 250,
    "discount": "20%",
    "category": "Washing Machine",
    "link": "/washing-machine"
  },
  {
    "id": 27,
    "title": "24'' LED TV",
    "description": "A high-definition LED TV that brings your entertainment to life with vibrant colors and crystal-clear images.",
    "images": [
      "../images/24s/1.jpeg",
      "../images/24s/2.jpeg",
      "../images/24s/3.jpeg",
      "../images/24s/4.jpeg",
      "../images/24s/5.jpeg",
      "../images/24s/6.jpeg"
    ],
    "features": [
      "4K Resolution",
      "Smart TV Features",
      "HDR Support",
      "Built-in Wi-Fi"
    ],
    "specifications": {
      "Size": "24 inches",
      "Resolution": "FHD",
      "Smart TV": "Yes",
      "Warranty": "1 Year"
    },
    "price": 600,
    "discount": "10%",
    "category": "LED TV",
    "link": "/led-tv"
  },
  {
    "id": 28,
    "title": "32'' LED TV",
    "description": "A high-definition LED TV that brings your entertainment to life with vibrant colors and crystal-clear images.",
    "images": [
      "../images/32s/1.jpeg",
      "../images/32s/2.jpeg",
      "../images/32s/3.jpeg",
      "../images/32s/4.jpeg",
      
    ],
    "features": [
      "4K Resolution",
      "Smart TV Features",
      "HDR Support",
      "Built-in Wi-Fi"
    ],
    "specifications": {
      "Size": "32 inches",
      "Resolution": "4K UHD",
      "Smart TV": "Yes",
      "Warranty": "1 Year"
    },
    "price": 600,
    "discount": "10%",
    "category": "LED TV",
    "link": "/led-tv"
  },
  {
    "id": 29,
    "title": "43'' LED TV",
    "description": "A high-definition LED TV that brings your entertainment to life with vibrant colors and crystal-clear images.",
    "images": [
      "../images/43s/1.jpeg",
      "../images/43s/2.jpeg",
      "../images/43s/3.jpeg",
      "../images/43s/4.jpeg",
      
    ],
    "features": [
      "4K Resolution",
      "Smart TV Features",
      "HDR Support",
      "Built-in Wi-Fi"
    ],
    "specifications": {
      "Size": "43 inches",
      "Resolution": "4K UHD",
      "Smart TV": "Yes",
      "Warranty": "1 Year"
    },
    "price": 600,
    "discount": "10%",
    "category": "LED TV",
    "link": "/led-tv"
  },
  {
    "id": 30,
    "title": "Speaker",
    "description": "A high-definition LED TV that brings your entertainment to life with vibrant colors and crystal-clear images.",
    "images": [
      "../images/speaker/1.jpeg",
      "../images/speaker/2.jpeg",
      "../images/speaker/3.jpeg",
      "../images/speaker/4.jpeg",
      
    ],
    "features": [
      "4K Resolution",
      "Smart TV Features",
      "HDR Support",
      "Built-in Wi-Fi"
    ],
    "specifications": {
      "Size": "43 inches",
      "Resolution": "4K UHD",
      "Smart TV": "Yes",
      "Warranty": "1 Year"
    },
    "price": 600,
    "discount": "10%",
    "category": "Speaker",
    "link": "/speaker"
  }


];


export const products = [
  {
    id: 27,
    title: "24'' LED TV",
    description: "A high-definition LED TV that brings your entertainment to life with vibrant colors and crystal-clear images.",
    images: [
      "../images/24s/1.jpeg",
      "../images/24s/2.jpeg",
      "../images/24s/3.jpeg",
      "../images/24s/4.jpeg",
      "../images/24s/5.jpeg",
      "../images/24s/6.jpeg"
    ],
    features: [
      "4K Resolution",
      "Smart TV Features",
      "HDR Support",
      "Built-in Wi-Fi"
    ],
    specifications: {
      Size: "24 inches",
      Resolution: "FHD",
      Smart_TV: "Yes",
      Warranty: "1 Year"
    },
    price: 8500, // Updated Price
    discount: "10%",
    category: "LED TV",
    link: "/led-tv"
  },
  {
    id: 28,
    title: "32'' LED TV",
    description: "A high-definition LED TV that brings your entertainment to life with vibrant colors and crystal-clear images.",
    images: [
      "../images/32s/1.jpeg",
      "../images/32s/2.jpeg",
      "../images/32s/3.jpeg",
      "../images/32s/4.jpeg",
    ],
    features: [
      "4K Resolution",
      "Smart TV Features",
      "HDR Support",
      "Built-in Wi-Fi"
    ],
    specifications: {
      Size: "32 inches",
      Resolution: "4K UHD",
      Smart_TV: "Yes",
      Warranty: "1 Year"
    },
    price: 10060, // Updated Price
    discount: "10%",
    category: "LED TV",
    link: "/led-tv"
  },
  {
    id: 29,
    title: "43'' LED TV",
    description: "A high-definition LED TV that brings your entertainment to life with vibrant colors and crystal-clear images.",
    images: [
      "../images/43s/1.jpeg",
      "../images/43s/2.jpeg",
      "../images/43s/3.jpeg",
      "../images/43s/4.jpeg",
    ],
    features: [
      "4K Resolution",
      "Smart TV Features",
      "HDR Support",
      "Built-in Wi-Fi"
    ],
    specifications: {
      Size: "43 inches",
      Resolution: "4K UHD",
      Smart_TV: "Yes",
      Warranty: "1 Year"
    },
    price: 12000, // Updated Price
    discount: "10%",
    category: "LED TV",
    link: "/led-tv"
  },
  {
    id: 4,
    title: "Cooler",
    description: "A room cooler that provides a refreshing breeze and cool air during the hot summer months.",
    images: [
      "../images/cooler/1.jpeg"
    ],
    features: [
      "Eco-friendly",
      "Low Power Consumption",
      "Adjustable Air Flow",
      "Water Tank Capacity: 30L"
    ],
    specifications: {
      Size: "Large",
      CoolingArea: "250 sq. ft.",
      Power: "150W",
      Warranty: "1 Year"
    },
    price: 6200, // Updated Price
    discount: "25%",
    category: "Cooler",
    link: "/cooler"
  },
  {
    id: 13,
    title: "Cooler",
    description: "A room cooler that provides a refreshing breeze and cool air during the hot summer months.",
    images: [
      "../images/cooler/2.jpeg"
    ],
    features: [
      "Eco-friendly",
      "Low Power Consumption",
      "Adjustable Air Flow",
      "Water Tank Capacity: 30L"
    ],
    specifications: {
      Size: "Large",
      CoolingArea: "250 sq. ft.",
      Power: "150W",
      Warranty: "1 Year"
    },
    price: 6100, // Updated Price
    discount: "25%",
    category: "Cooler",
    link: "/cooler"
  },
  {
    id: 14,
    title: "Cooler",
    description: "A room cooler that provides a refreshing breeze and cool air during the hot summer months.",
    images: [
      "../images/cooler/3.jpeg"
    ],
    features: [
      "Eco-friendly",
      "Low Power Consumption",
      "Adjustable Air Flow",
      "Water Tank Capacity: 30L"
    ],
    specifications: {
      Size: "Large",
      CoolingArea: "250 sq. ft.",
      Power: "150W",
      Warranty: "1 Year"
    },
    price: 6800, // Updated Price
    discount: "25%",
    category: "Cooler",
    link: "/cooler"
  },
  {
    id: 15,
    title: "Cooler",
    description: "A room cooler that provides a refreshing breeze and cool air during the hot summer months.",
    images: [
      "../images/cooler/4.jpeg"
    ],
    features: [
      "Eco-friendly",
      "Low Power Consumption",
      "Adjustable Air Flow",
      "Water Tank Capacity: 30L"
    ],
    specifications: {
      Size: "Large",
      CoolingArea: "250 sq. ft.",
      Power: "150W",
      Warranty: "1 Year"
    },
    price: 6400, // Updated Price
    discount: "25%",
    category: "Cooler",
    link: "/cooler"
  },
  {
    id: 16,
    title: "Cooler",
    description: "A room cooler that provides a refreshing breeze and cool air during the hot summer months.",
    images: [
      "../images/cooler/5.jpeg"
    ],
    features: [
      "Eco-friendly",
      "Low Power Consumption",
      "Adjustable Air Flow",
      "Water Tank Capacity: 30L"
    ],
    specifications: {
      Size: "Large",
      CoolingArea: "250 sq. ft.",
      Power: "150W",
      Warranty: "1 Year"
    },
    price: 7400, // Updated Price
    discount: "25%",
    category: "Cooler",
    link: "/cooler"
  },
  {
    id: 17,
    title: "Cooler",
    description: "A room cooler that provides a refreshing breeze and cool air during the hot summer months.",
    images: [
      "../images/cooler/6.jpeg"
    ],
    features: [
      "Eco-friendly",
      "Low Power Consumption",
      "Adjustable Air Flow",
      "Water Tank Capacity: 30L"
    ],
    specifications: {
      Size: "Large",
      CoolingArea: "250 sq. ft.",
      Power: "150W",
      Warranty: "1 Year"
    },
    price: 8950, // Updated Price
    discount: "25%",
    category: "Cooler",
    link: "/cooler"
  },
  {
    id: 18,
    title: "Cooler",
    description: "A room cooler that provides a refreshing breeze and cool air during the hot summer months.",
    images: [
      "../images/cooler/7.jpeg"
    ],
    features: [
      "Eco-friendly",
      "Low Power Consumption",
      "Adjustable Air Flow",
      "Water Tank Capacity: 30L"
    ],
    specifications: {
      Size: "Large",
      CoolingArea: "250 sq. ft.",
      Power: "150W",
      Warranty: "1 Year"
    },
    price: 8900, // Updated Price
    discount: "25%",
    category: "Cooler",
    link: "/cooler"
  },
  {
    id: 19,
    title: "Cooler",
    description: "A room cooler that provides a refreshing breeze and cool air during the hot summer months.",
    images: [
      "../images/cooler/8.jpeg"
    ],
    features: [
      "Eco-friendly",
      "Low Power Consumption",
      "Adjustable Air Flow",
      "Water Tank Capacity: 30L"
    ],
    specifications: {
      Size: "Large",
      CoolingArea: "250 sq. ft.",
      Power: "150W",
      Warranty: "1 Year"
    },
    price: 10050, // Updated Price
    discount: "25%",
    category: "Cooler",
    link: "/cooler"
  },
  {
    id: 20,
    title: "Cooler",
    description: "A room cooler that provides a refreshing breeze and cool air during the hot summer months.",
    images: [
      "../images/cooler/9.jpeg"
    ],
    features: [
      "Eco-friendly",
      "Low Power Consumption",
      "Adjustable Air Flow",
      "Water Tank Capacity: 30L"
    ],
    specifications: {
      Size: "Large",
      CoolingArea: "250 sq. ft.",
      Power: "150W",
      Warranty: "1 Year"
    },
    price: 8500, // Updated Price
    discount: "25%",
    category: "Cooler",
    link: "/cooler"
  },
  {
    id: 21,
    title: "AC",
    description: "A high-performance air conditioner that ensures your room stays cool and comfortable during the hot summer months.",
    images: [
      "../images/ac/1.jpeg"
    ],
    features: [
      "Energy Efficient",
      "Remote Control",
      "Multi-Stage Filtration",
      "Silent Operation"
    ],
    specifications: {
      CoolingCapacity: "1.5 Ton",
      EnergyRating: "5 Star",
      PowerConsumption: "1500W",
      Warranty: "1 Year"
    },
    price: 19900, // Updated Price
    discount: "15%",
    category: "AC",
    link: "/ac"
  },
  {
    id: 22,
    title: "AC",
    description: "A high-performance air conditioner that ensures your room stays cool and comfortable during the hot summer months.",
    images: [
      "../images/ac/2.jpeg"
    ],
    features: [
      "Energy Efficient",
      "Remote Control",
      "Multi-Stage Filtration",
      "Silent Operation"
    ],
    specifications: {
      CoolingCapacity: "1.5 Ton",
      EnergyRating: "5 Star",
      PowerConsumption: "1500W",
      Warranty: "1 Year"
    },
    price: 22000, // Updated Price
    discount: "15%",
    category: "AC",
    link: "/ac"
  },
  {
    id: 23,
    title: "AC",
    description: "A high-performance air conditioner that ensures your room stays cool and comfortable during the hot summer months.",
    images: [
      "../images/ac/3.jpeg"
    ],
    features: [
      "Energy Efficient",
      "Remote Control",
      "Multi-Stage Filtration",
      "Silent Operation"
    ],
    specifications: {
      CoolingCapacity: "1.5 Ton",
      EnergyRating: "5 Star",
      PowerConsumption: "1500W",
      Warranty: "1 Year"
    },
    price: 21600, // Updated Price
    discount: "15%",
    category: "AC",
    link: "/ac"
  }
  ,
  {
    id: 40,
    title: "Washing Machine",
    description: "A high-capacity washing machine that delivers powerful performance while being energy-efficient.",
    images: [
      "../images/washing-machine/1.jpeg"
    ],
    features: [
      "Energy Efficient",
      "Multiple Wash Modes",
      "Easy to Use",
      "Large Capacity"
    ],
    specifications: {
      Capacity: "7.0 kg",
      Type: "Front Load",
      SpinSpeed: "1200 RPM",
      Warranty: "1 Year"
    },
    price: 6700, // Updated Price
    discount: "20%",
    category: "Washing Machine",
    link: "/washing-machine"
  },
  {
    id: 50,
    title: "SOUNDBAR -07",
    description: "A premium soundbar with powerful audio output, designed to enhance your home entertainment experience.",
    images: [
      "../images/speakers/1.jpeg"
    ],
    features: [
      "High-quality sound",
      "Bluetooth Connectivity",
      "Sleek Design",
      "Remote Control"
    ],
    specifications: {
      Power: "50W",
      Connectivity: "Bluetooth, AUX",
      Warranty: "1 Year"
    },
    price: 3250, // Updated Price
    discount: "10%",
    category: "Soundbar",
    link: "/soundbar"
  },
  {
    id: 51,
    title: "RAMBO (SINGLE TOWER)",
    description: "A high-power single tower speaker with impressive sound quality and deep bass for all your party needs.",
    images: [
      "../images/speakers/2.jpeg"
    ],
    features: [
      "Deep Bass",
      "LED Light Effects",
      "Bluetooth Connectivity",
      "USB and AUX Input"
    ],
    specifications: {
      Power: "100W",
      Connectivity: "Bluetooth, USB, AUX",
      Warranty: "1 Year"
    },
    price: 3250, // Updated Price
    discount: "10%",
    category: "Speaker",
    link: "/speaker"
  },
  {
    id: 52,
    title: "Panda (SINGLE TOWER)",
    description: "A powerful single tower speaker for high-quality sound and excellent bass performance.",
    images: [
      "../images/speakers/3.jpeg"
    ],
    features: [
      "Powerful Bass",
      "Bluetooth Connectivity",
      "FM Radio",
      "LED Lights"
    ],
    specifications: {
      Power: "80W",
      Connectivity: "Bluetooth, USB, AUX",
      Warranty: "1 Year"
    },
    price: 3350, // Updated Price
    discount: "10%",
    category: "Speaker",
    link: "/speaker"
  },
  {
    id: 53,
    title: "Tower –D-88 (SINGLE TOWER)",
    description: "A tower speaker designed for large spaces, delivering clear audio with enhanced bass.",
    images: [
      "../images/speakers/4.jpeg"
    ],
    features: [
      "Clear Audio",
      "Bluetooth Connectivity",
      "USB, AUX Input",
      "FM Radio"
    ],
    specifications: {
      Power: "120W",
      Connectivity: "Bluetooth, USB, AUX",
      Warranty: "1 Year"
    },
    price: 3950, // Updated Price
    discount: "10%",
    category: "Speaker",
    link: "/speaker"
  },
  {
    id: 54,
    title: "8006 / 8007 (SINGLE TOWER)",
    description: "A high-performance single tower speaker designed for enhanced audio experience with built-in LED lights.",
    images: [
      "../images/speakers/5.jpeg"
    ],
    features: [
      "LED Lights",
      "Bluetooth Connectivity",
      "USB and AUX Support",
      "Powerful Sound"
    ],
    specifications: {
      Power: "150W",
      Connectivity: "Bluetooth, USB, AUX",
      Warranty: "1 Year"
    },
    price: 4250, // Updated Price
    discount: "10%",
    category: "Speaker",
    link: "/speaker"
  },
  {
    id: 55,
    title: "8001 (SINGLE TOWER)",
    description: "A premium tower speaker that combines power, performance, and LED lighting to elevate your audio experience.",
    images: [
      "../images/speakers/6.jpeg"
    ],
    features: [
      "Premium Sound",
      "LED Lighting Effects",
      "Bluetooth Connectivity",
      "USB and AUX Ports"
    ],
    specifications: {
      Power: "160W",
      Connectivity: "Bluetooth, USB, AUX",
      Warranty: "1 Year"
    },
    price: 4350, // Updated Price
    discount: "10%",
    category: "Speaker",
    link: "/speaker"
  },
  {
    id: 56,
    title: "8003 (SINGLE TOWER)",
    description: "A powerful single tower speaker with advanced audio features, perfect for parties and events.",
    images: [
      "../images/speakers/7.jpeg"
    ],
    features: [
      "Powerful Audio",
      "LED Lights",
      "Bluetooth and AUX Connectivity",
      "USB Support"
    ],
    specifications: {
      Power: "200W",
      Connectivity: "Bluetooth, USB, AUX",
      Warranty: "1 Year"
    },
    price: 4500, // Updated Price
    discount: "10%",
    category: "Speaker",
    link: "/speaker"
  },
  {
    id: 57,
    title: "Trolley Speaker (8000)",
    description: "A portable trolley speaker with high-quality sound, designed for both indoor and outdoor use.",
    images: [
      "../images/speakers/8.jpeg"
    ],
    features: [
      "Portable",
      "Trolley Design",
      "Bluetooth Connectivity",
      "USB and AUX Inputs"
    ],
    specifications: {
      Power: "250W",
      Connectivity: "Bluetooth, USB, AUX",
      Warranty: "1 Year"
    },
    price: 4800, // Updated Price
    discount: "10%",
    category: "Speaker",
    link: "/speaker"
  },
  {
    id: 58,
    title: "Trolley Speaker (8080)",
    description: "A high-powered trolley speaker with strong bass and advanced audio features for parties and outdoor events.",
    images: [
      "../images/speakers/9.jpeg"
    ],
    features: [
      "High Bass",
      "Portable Trolley Design",
      "Bluetooth Connectivity",
      "FM Radio"
    ],
    specifications: {
      Power: "300W",
      Connectivity: "Bluetooth, USB, AUX",
      Warranty: "1 Year"
    },
    price: 5250, // Updated Price
    discount: "10%",
    category: "Speaker",
    link: "/speaker"
  },
  {
    id: 59,
    title: "GABBER (PAIR TOWER) WITHOUT MIC",
    description: "A powerful pair of tower speakers that deliver impressive sound without the need for a microphone.",
    images: [
      "../images/speakers/10.jpeg"
    ],
    features: [
      "Powerful Sound",
      "LED Lighting",
      "Bluetooth Connectivity",
      "USB and AUX Support"
    ],
    specifications: {
      Power: "500W",
      Connectivity: "Bluetooth, USB, AUX",
      Warranty: "1 Year"
    },
    price: 5200, // Updated Price
    discount: "10%",
    category: "Speaker",
    link: "/speaker"
  },
  {
    id: 60,
    title: "TIGER (PAIR TOWER) WITHOUT MIC",
    description: "A set of powerful tower speakers designed for large venues, with high-quality audio and advanced features.",
    images: [
      "../images/speakers/11.jpeg"
    ],
    features: [
      "Crystal-clear Audio",
      "Bluetooth Connectivity",
      "USB and AUX Ports",
      "LED Light Effects"
    ],
    specifications: {
      Power: "600W",
      Connectivity: "Bluetooth, USB, AUX",
      Warranty: "1 Year"
    },
    price: 5350, // Updated Price
    discount: "10%",
    category: "Speaker",
    link: "/speaker"
  },
  {
    id: 61,
    title: "TAHALKA (PAIR TOWER) WITHOUT MIC",
    description: "A high-performance pair of tower speakers with exceptional sound quality and sleek design.",
    images: [
      "../images/speakers/12.jpeg"
    ],
    features: [
      "High Performance",
      "LED Lights",
      "Bluetooth Connectivity",
      "USB and AUX Inputs"
    ],
    specifications: {
      Power: "700W",
      Connectivity: "Bluetooth, USB, AUX",
      Warranty: "1 Year"
    },
    price: 7600, // Updated Price
    discount: "10%",
    category: "Speaker",
    link: "/speaker"
  }
  // More products can be added in a similar way
];
