import React from "react";
import Layout from "./../components/Layout/Layout";
import { Box, Typography, Grid, Card, CardContent, CardMedia, Button } from "@mui/material";
import Banner from "../images/banner4.jpg";

// Replace with actual images
import applianceImage1 from "../images/banner4.jpg"; // Smart LED TV
import applianceImage2 from "../images/banner2.jpg"; // Induction Cooker
import applianceImage3 from "../images/banner3.jpg"; // Energy-Efficient AC
import StatsSection from "./StatsSection";

const About = () => {
  return (
    <Layout>
      <Box
        sx={{
          textAlign: "center",
          backgroundImage: `url(${Banner})`, // Set banner image as background
          backgroundSize: "cover",
          backgroundPosition: "center",
          py: 10, // Add vertical padding
          color: "#fff", // White text color for readability
          "& h4": {
            fontWeight: "bold",
            fontSize: "3rem",
            letterSpacing: "1.5px",
            textShadow: "2px 2px 5px rgba(0,0,0,0.7)",
            mb: 2,
          },
        }}
      >
        <Typography variant="h4">Welcome To DigiFlyer</Typography>
        <Typography variant="body1" sx={{ fontSize: "1.25rem", maxWidth: "800px", mx: "auto", mt: 2 }}>
          Your trusted partner for high-quality home appliances designed to make your life easier.
        </Typography>
        <Button
          variant="contained"
          sx={{
            mt: 3,
            backgroundColor: "#ff6f61", // Accent color
            "&:hover": {
              backgroundColor: "#ff3d2e", // Darker shade on hover
            },
          }}
        >
          Explore Our Products
        </Button>
      </Box>

      <Box
        sx={{
          my: 15,
          textAlign: "center",
          px: 2,
          "& h4": {
            fontWeight: "bold",
            my: 2,
            fontSize: "2.5rem",
            color: "#333",
          },
          "& p": {
            textAlign: "justify",
            color: "#555",
            fontSize: "1.1rem",
            lineHeight: 1.7,
            marginBottom: "1rem",
            maxWidth: "800px",
            marginLeft: "auto",
            marginRight: "auto",
          },
          "@media (max-width:600px)": {
            mt: 0,
            "& h4": {
              fontSize: "1.8rem",
            },
            "& p": {
              fontSize: "1rem",
              lineHeight: 1.6,
            },
          },
        }}
      >
        <Typography variant="h4">About DigiFlyer</Typography>

        <Typography variant="body1">
          At DigiFlyer, we specialize in providing high-quality home appliances designed to make your life easier and more efficient. Whether it's state-of-the-art Smart LED TVs, energy-efficient air conditioners, or innovative home automation solutions, we offer a broad range of products to meet every need in the modern home.
        </Typography>

        <Typography variant="body1">
          Since our inception in 2024, we’ve been committed to providing appliances that combine cutting-edge technology, superior quality, and unmatched customer service. Our team of experts works closely with manufacturers to ensure that every product we offer meets our rigorous standards of excellence.
        </Typography>

        {/* Statistics and Key Numbers */}
        <StatsSection /> 

        <Typography variant="body1">
          Our product range includes everything from the latest Smart Home gadgets, such as voice-controlled devices, to energy-saving home appliances like refrigerators, washing machines, and air purifiers. No matter the size or need of your household, we have the right appliance to fit your lifestyle.
        </Typography>

        {/* Image grid with hover effect */}
        <Grid container spacing={4} sx={{ my: 5 }}>
          <Grid item xs={12} sm={4}>
            <Card sx={{ "&:hover": { transform: "scale(1.05)", boxShadow: 6 }, transition: "transform 0.3s ease, box-shadow 0.3s ease" }}>
              <CardMedia
                component="img"
                height="200"
                image={applianceImage1}
                alt="Smart LED TV"
              />
              <CardContent>
                <Typography variant="h6" align="center">
                  Smart LED TVs
                </Typography>
                <Typography variant="body2" align="center">
                  Experience the future of home entertainment with our range of Smart LED TVs, designed for clarity, speed, and ease of use.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card sx={{ "&:hover": { transform: "scale(1.05)", boxShadow: 6 }, transition: "transform 0.3s ease, box-shadow 0.3s ease" }}>
              <CardMedia
                component="img"
                height="200"
                image={applianceImage2}
                alt="Induction Cooker"
              />
              <CardContent>
                <Typography variant="h6" align="center">
                  Induction Cookers
                </Typography>
                <Typography variant="body2" align="center">
                  Cook your meals faster and more efficiently with our range of induction cookers, offering precise temperature control and energy savings.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card sx={{ "&:hover": { transform: "scale(1.05)", boxShadow: 6 }, transition: "transform 0.3s ease, box-shadow 0.3s ease" }}>
              <CardMedia
                component="img"
                height="200"
                image={applianceImage3}
                alt="Energy-Efficient AC"
              />
              <CardContent>
                <Typography variant="h6" align="center">
                  Energy-Efficient ACs
                </Typography>
                <Typography variant="body2" align="center">
                  Stay cool and save energy with our advanced air conditioning systems, engineered to provide maximum efficiency and comfort.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Typography variant="body1">
          At DigiFlyer, we are dedicated to offering products that improve the daily lives of our customers. Our commitment to innovation, sustainability, and customer satisfaction has made us a trusted name in the home appliance industry. We are constantly innovating to bring you the latest in home technology to make your life easier and more convenient.
        </Typography>

        <Typography variant="body1">
          Whether you are looking to upgrade your kitchen, enhance your living room entertainment, or automate your entire home, DigiFlyer has the perfect solution. Our team is here to help you choose the right products and provide reliable after-sales support. Join us in revolutionizing the way you live with our cutting-edge home appliances.
        </Typography>
      </Box>
    </Layout>
  );
};

export default About;
