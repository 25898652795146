import React from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { Box } from "@mui/material"; // Material-UI Box for styling
import L from "leaflet"; // Leaflet for custom markers
import "leaflet/dist/leaflet.css"; // Import Leaflet CSS for proper map styling

const MapWithCoordinates = ({ coordinates }) => {
  const initialPosition = [22.735, 75.865]; // Initial map center

  // Create a custom icon for the marker
  const customIcon = new L.Icon({
    iconUrl: "https://png.pngtree.com/png-clipart/20220224/ourmid/pngtree-3d-pin-map-location-marker-front-view-png-image_4462594.png", // URL to your custom marker image
    iconSize: [32, 32], // Size of the marker
    iconAnchor: [16, 32], // Position the anchor point to the bottom of the marker (so it points to the location)
    popupAnchor: [0, -32], // Offset the popup to appear above the marker
  });

  return (
    <Box
      sx={{
        width: "100%",
        height: "450px", // Defined height for the map container
        maxWidth: "1200px", // Optional: max width for the container
        margin: "0 auto", // Optional: center the map container
        borderRadius: "8px", // Optional: rounded corners
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Optional: box shadow
      }}
    >
      <MapContainer
        center={initialPosition}
        zoom={13}
        style={{ width: "100%", height: "100%" }} // Map takes full height of container
        scrollWheelZoom={true} // Enable scroll zooming
      >
        {/* OpenStreetMap TileLayer */}
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        {/* Markers from the coordinates with custom marker icon */}
        {coordinates.map(({ location, name, link }, index) => (
          <Marker
            key={index}
            position={location}
            icon={customIcon} // Apply the custom icon
          >
            <Popup>
              <a href={link} target="_blank" rel="noopener noreferrer">
                {name}
              </a>
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    </Box>
  );
};

export default MapWithCoordinates;
