import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Menu from "./pages/Menu";
import Pagenotfound from "./pages/Pagenotfound";
import Products from "./pages/Products";
import ProductDetails from "./pages/ProductDetails";
import ProductDetail from "./pages/ProductDetails";
import { CartProvider } from "./pages/CartContext";
import CartPage from "./pages/CartPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import FAQ from "./pages/FAQ";
import ScrollToTop from "./components/Layout/ScrollToTop";
import Ads from "./pages/Ads";
function App() {
  return (
    <div>
      <CartProvider>

        <BrowserRouter>
          <ScrollToTop />

          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/ads" element={<Ads/>} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/menu" element={<Menu />} />
            <Route path="/products" element={<Products />} />
            <Route path="/CartPage" element={<CartPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/faq" element={<FAQ />} />
            {/* <Route path="/product/:id" component={ProductDetails} /> */}
            <Route path="/product/:id" element={<ProductDetail />} />  {/* Make sure to pass the element here */}

            <Route path="*" element={<Pagenotfound />} />
          </Routes>
        </BrowserRouter>
      </CartProvider>
    </div>
  );
}

export default App;
