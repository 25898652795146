import React, { createContext, useState, useContext, useEffect } from "react";

// Create a context for the cart
const CartContext = createContext();

// Create a provider component
export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);

  // Load cart data from localStorage when the app loads
  useEffect(() => {
    const savedCart = JSON.parse(localStorage.getItem("cart")) || [];
    setCart(savedCart);
  }, []);

  // Update localStorage whenever the cart changes
  useEffect(() => {
    if (cart.length > 0) {
      localStorage.setItem("cart", JSON.stringify(cart));
    }
  }, [cart]);

  // Function to add items to the cart
  const addToCart = (product) => {
    setCart((prevCart) => {
      const isProductInCart = prevCart.find((item) => item.id === product.id);
      if (isProductInCart) {
        // Update the quantity if the product is already in the cart
        return prevCart.map((item) =>
          item.id === product.id ? { ...item, quantity: item.quantity + 1 } : item
        );
      } else {
        // Add the product to the cart with quantity 1 if it's not in the cart
        return [...prevCart, { ...product, quantity: 1 }];
      }
    });
  };

  // Function to remove items from the cart
  const removeFromCart = (productId) => {
    setCart((prevCart) => prevCart.filter((item) => item.id !== productId));
  };

  // Function to update the quantity of an item in the cart
  const updateQuantity = (productId, quantity) => {
    setCart((prevCart) => {
      return prevCart.map((item) =>
        item.id === productId ? { ...item, quantity } : item
      );
    });
  };

  // Function to clear the entire cart
  const clearCart = () => {
    setCart([]);
    localStorage.removeItem("cart"); // Also remove from localStorage
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        addToCart,
        removeFromCart,
        updateQuantity,
        clearCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

// Custom hook to use the cart context
export const useCart = () => useContext(CartContext);




// import React, { createContext, useState, useContext } from "react";

// // Create a context for the cart
// const CartContext = createContext();

// // Create a provider component
// export const CartProvider = ({ children }) => {
//   const [cart, setCart] = useState([]);

//   // Function to add items to the cart
//   const addToCart = (product) => {
//     setCart((prevCart) => {
//       const isProductInCart = prevCart.find((item) => item.id === product.id);
//       if (isProductInCart) {
//         // Update the quantity if the product is already in the cart
//         return prevCart.map((item) =>
//           item.id === product.id ? { ...item, quantity: item.quantity + 1 } : item
//         );
//       } else {
//         // Add the product to the cart with quantity 1 if it's not in the cart
//         return [...prevCart, { ...product, quantity: 1 }];
//       }
//     });
//   };

//   // Function to remove items from the cart
//   const removeFromCart = (productId) => {
//     setCart((prevCart) => prevCart.filter((item) => item.id !== productId));
//   };

//   // Function to update the quantity of an item in the cart
//   const updateQuantity = (productId, quantity) => {
//     setCart((prevCart) => {
//       return prevCart.map((item) =>
//         item.id === productId ? { ...item, quantity } : item
//       );
//     });
//   };

//   // Function to clear the entire cart
//   const clearCart = () => {
//     setCart([]);
//   };

//   return (
//     <CartContext.Provider
//       value={{
//         cart,
//         addToCart,
//         removeFromCart,
//         updateQuantity,
//         clearCart,
//       }}
//     >
//       {children}
//     </CartContext.Provider>
//   );
// };

// // Custom hook to use the cart context
// export const useCart = () => useContext(CartContext);
